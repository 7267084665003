import React from 'react'
import { toFixed } from '../../helpers/Converts'
import { AF19f } from '../../helpers/reportHelp'

const SizingAssumptions = ({res,I40}) => {
  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                        colSpan={2}
                      >
                        Si
                      </th>
                      <th
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                        colSpan={2}
                      >
                        Field
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        K Value
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.config_specific?.K_value.toFixed(2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        K<sub className="align-sub">s</sub>
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.K_s, 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        Hv as ratio of D
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.H_v_ratio_of_D, 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        L. Liq. / Gas Particle Dia.
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.process?.K27[0], 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        µm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.process?.K27[1], 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        ft
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        L. Liq. / H. Liq. Particle Dia.{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.process?.K31[0], 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        µm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.process?.K31[1], 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        ft
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        Over Design Factor
                      </td>
                      <td
                        colSpan={4}
                        style={{
                          border: "1px solid black",
                          padding: "3px 8px",
                          textAlign: "center",
                        }}
                      >
                        {AF19f(
                          I40,
                          res?.process?.gas,
                          res?.process?.l_liq,
                          res?.process?.h_liq,
                          res?.process?.Over_Design_Factor
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        L. Liq. Level below weir
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.L_Liq_level, 4) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {(res?.config_specific?.L_Liq_level / 25.4).toFixed(2) ||
                          "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        H. Liq. Level below weir
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.H_Liq_level_below_weir, 4) ||
                          "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {(res?.config_specific?.L_Liq_level / 25.4).toFixed(2) ||
                          "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        HA
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.config_specific?.H_a * 304.8 || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.config_specific?.H_a || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        ft
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        WD
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.W_d / 25.4, 3) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.config_specific?.W_d || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        HLL in Boot
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.HL_l_in_Boot * 304.8, 3) ||
                          "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.HL_l_in_Boot, 3) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        ft
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        HHL in Boot
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.config_specific?.HH_L_in_Boot || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.config_specific?.HH_L_in_Boot / 304.8, 3) ||
                          "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        ft
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={5}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        Holdup Time{" "}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        Light{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res?.configuration?.Holdup_Time_L_Lig, 3) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        min.
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        Heavy{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.configuration?.Holdup_Time_H_Lig || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        min.
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={5}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        Surge Time
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        Light
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.configuration?.Surge_Time_L_Liq || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        min.
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        Heavy{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {res?.configuration?.Surge_Time_H_Liq || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        min.
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                  </tbody>
                </table>
  )
}

export default SizingAssumptions