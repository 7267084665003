import React from 'react'
import CaseChart from './CaseChart'
import CaseTable from './CaseTable'
import Header from './reports/Header'

function ReportChart({I40,res}) {
  return (
    <table
    style={{
      borderCollapse: 'collapse',
      width: '90%',
      margin: 'auto',
      overflow: 'hidden',
    }}
  >
  <Header res={res} sheet={3} />
    <tbody>
      <tr>
        <td className="h-[50px]"></td>
      </tr>
      <tr>
        <td className="!text-[11px] !font-bold">Weight Analysis</td>
      </tr>
      <tr>
        <td colSpan={5} className='!w-[400px] !max-w-[400px] overflow-hidden '>
        <CaseTable sizing_id={res?.sizing.id} case_study={res?.preferred?.selected_case}/>
       
        </td>
      </tr>
     
      <tr>
        <td colSpan={5} style={{textAlign:"center"}}>
        <CaseChart sizing_id={res.sizing.id}/>
        </td>
      </tr>
    </tbody>
  </table>
  )
}

export default ReportChart