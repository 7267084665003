import React, { useEffect, useState } from "react";
import useApiClient from "../../Settings/useApiClient";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { orderApi } from "../../API/orderAPi";
import { Alert, Button } from "antd";
import { monthToName } from "../../Tools/tools";
import { PrinterOutlined } from "@ant-design/icons";
import { paymentApi } from "../../API/paymentApi";
import { ParsError } from "../../Tools/ParsError";
import useNotify from "../../hooks/useNotify";

const Invoice = () => {
  const apiClient = useApiClient();
  const { oid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openNotification } = useNotify();

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState("checking...");
  useEffect(() => {
    const _token = searchParams.get("token") ?? "";
    const _ba_token = searchParams.get("ba_token") ?? "";
    const _subscription_id = searchParams.get("subscription_id") ?? "";
    if (_token) {
      const data = {
        _token: _token,
        subscription_id: _subscription_id,
        ba_token: _ba_token,
      };
      orderApi
        .verify(apiClient, oid, data)
        .then((res) => {
          setStatus(res.status);
          setOrder(res.order);
        })
        .finally(() => setLoading(false));
    } else
      orderApi
        .invoice(apiClient, oid)
        .then((res) => {
          setOrder(res);
        })
        .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    
    document.title = "OmniSEP Invoice_" + order?.code;
  },[order]);
  const submit = () => {
    paymentApi
      .pay_in_invoice(apiClient, oid)
      .then((r) => {
        document.location.href = r;
      })
      .catch((e) => {
        openNotification("Error", ParsError(e?.response), "Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="a4-page-invoice bg-white ">
      <div className="invoice grid p-20   ">
        <div className="callout large invoice-container">
          <table className="invoice w-full p-10">
            <tr className="header">
              <td className="">
                <img src="/images/logo.svg" alt="Company Name" />
              </td>
              <td className="align-right">
                <h2>Invoice</h2>
              </td>
            </tr>
            <tr className="intro">
              <td className="w-36">
                Payment Status
                <br />
                <Alert
                  type={order?.status == 1 ? "success" : "error"}
                  className="font-bold "
                  message={order?.status_readable}
                />
                {order?.status != 1 ? (
                  <div className="w-full text-center  mt-3 flex place-items-center justify-stretch ">
                    <Button
                      htmlType="button"
                      disabled={loading}
                      loading={loading}
                      onClick={() => submit()}
                      className="w-36  m-auto   rounded  text-white bg-[--color-3] hover:bg-gray-900  font-medium  text-basic  py-2  "
                    >
                      <span className=""> Pay Now</span>
                      {/* <sub className='text-xs'>One-time payment</sub> */}
                    </Button>
                    {/* <Button
                  htmlType="button"
                  disabled={loading}
                  loading={loading}
                  // onClick={()=>submit("subscribe")}
                  className=" w-36 m-auto grid h-16 l    rounded  text-white bg-[--color-1] hover:bg-gray-900  font-medium  text-lg  py-2 me-2 "
                >
                <span className=''>  Subscribe</span>
              
                </Button> */}
                  </div>
                ) : null}
              </td>
              <td className="text-right">
                <span className="num">Order #{order?.code}</span>
                <br />
                {order?.date}
              </td>
            </tr>
            <tr className="details">
              <td colSpan="2">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="desc">Item Description</th>
                      <th className="id">Payment Type</th>
                      <th className="qty">Quantity</th>
                      <th className="amt">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="item">
                      <td className="desc">{order?.title}</td>
                      <td className="id num">
                        {monthToName(order?.subscription_period?.months)}
                      </td>
                      <td className="qty">1</td>
                      <td className="amt">£{order?.price}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="totals">
              <td></td>
              <td>
                <table className="w-2/4 ms-auto">
                  <tr className="subtotal">
                    <td className="num">Subtotal</td>
                    <td className="num">£{order?.price}</td>
                  </tr>
                  {/* <tr className="fees">
                    <td className="num">Shipping & Handling</td>
                    <td className="num">£0.00</td>
                  </tr> */}
                  {/* <tr className="tax">
                  <td className="num">Tax (7%)</td>
                  <td className="num">£7.00</td>
                </tr> */}
                  <tr className="total">
                    <td>Total</td>
                    <td>£{order?.price}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <div className="additional-info mt-20 w-full">
            <div className="grid grid-cols-2 justify-between w-full">
              <div className="columns">
                <h5>Billing Information</h5>
                <p>
                  {order?.card_info?.name}
                  <br />
                  {order?.card_info?.address_street}
                  <br />
                  {order?.card_info?.address_city}
                  <br />
                  {order?.card_info?.address_country}
                  <br />
                  {order?.card_info?.address_postcode}
                </p>
              </div>

              <div className="columns grid justify-end">
                <h5>Company Information</h5>
                <p>
                  OmniSEP <br />
                  Cumulus Engineering Ltd
                  <br />
                  71-75 Shelton Street, Covent Garden,
                  <br />
                  London, United Kingdom WC2H 9JQ
                </p>
              </div>
            </div>
          </div>
        </div>
        <sub className="text-center mt-20 w-full">
          © 2025 OmniSEP. All rights reserved.
        </sub>
      </div>
      <div className="grid text-center print:hidden gap-3 mt-4">
        <Button
          className="submit  m-auto w-48 !h-10 "
          icon={<PrinterOutlined />}
          onClick={() => window.print()}
        >
          {" "}
          Print or save as PDF
        </Button>

        <Link className=" m-auto w-48 border rounded py-1" to={"/invoices"}>
          {" "}
          {"<"} Back to Invoices
        </Link>
      </div>
    </div>
  );
};

export default Invoice;
