import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case2_2({info}) {
    return (
        <>
         
                
         <div className="HHLL-2-1 grid text-start  leading-4">
             <div className='flex items-center justify-between gap-1'><span>HHLL</span><span>{toFixed(info.AN71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>HLL</span><span>{toFixed(info.AK71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>NLL</span><span>{toFixed(info.AH71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>LLL</span><span>{toFixed(info.AA71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>LLLL</span><span>{toFixed(info.X71,0)}</span></div>
          </div>
          <div className="HHLIL grid text-start leading-4">
           <div className='flex items-center justify-between gap-1'><span>HHLIL</span><span>{toFixed(info.T71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>HLIL</span><span>{toFixed(info.Q71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>NLIL</span><span>{toFixed(info.N71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>LLIL</span><span>{toFixed(info.G71,0)}</span></div>
                               <div className='flex items-center justify-between gap-1'><span>LLLIL</span><span>{toFixed(info.D71,0)}</span></div>
          </div>
          <div className="L">
            <label>L </label>{toFixed(info.BH75,3)}
          </div>
          <div className="D">
            <label>D </label>{toFixed(info.BD75,3)}
          </div>
          <div className="hd">
          <label>H<sub className="align-sub ">d</sub> </label>{info.BH71}
          </div>
          <div className="hbn">
            <label>H<sub className="align-sub ">bn</sub> </label>{info.BD71}
          </div>
          <div className="ha-2">
            <label>H<sub className="align-sub ">a</sub> </label>{toFixed(info.D66,1)} ft
          </div>
          <div className="w_d-2-1">
            <label>W<sub className="align-sub ">d</sub> </label>{toFixed(info.G66,1)} in.
          </div>
        </>)
}

export default Case2_2