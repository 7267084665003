import { Affix, Badge, Card, Divider, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DownloadOutlined, ProjectOutlined } from "@ant-design/icons";

import { ProjectContext } from "../Context/ProjectContext";
import TextArea from "antd/es/input/TextArea";
import Link from "antd/es/typography/Link";
export const SidebarSizing = ({ project }) => {
  const { sizing, active, setActive, updateSizing } =
    useContext(ProjectContext);
  const [top, setTop] = useState(120);
  const [note, setNote] = useState("");
  const onChangeNote = () => {
    updateSizing({ note: note });
  };
  useEffect(() => {
    setNote(sizing?.note);
  }, [sizing]);
  return (
    <Affix offsetTop={top} className="ms-3 me-3">
      <div className="overflow-y-auto h-[85vh] pe-2">
        <Card type="inner" title="Workflow" className="p-0 min-h-0">
          <div className="flex gap-2">
            <ProjectOutlined style={{ fontSize: 20 }} />

            <Typography.Text className="font-semibold">
              Project:{" "}
              <span>
                {project?.name}
                {"("}
                {project?.initials}
                {")"}
              </span>
            </Typography.Text>
          </div>
          <div className="p-1 mt-3 bg-gray-100">
            <Typography.Text className="font-semibold">
              {" "}
              Seperator Tag:
            </Typography.Text>{" "}
            {sizing?.separator_tag}
          </div>
          <div>
            <TextArea
              onBlur={onChangeNote}
              onChange={(e) => setNote(e.target.value)}
              className="text-[11px]"
              value={note}
              rows={4}
              placeholder="Add Note..."
            ></TextArea>
          </div>
        </Card>
        <Card style={{ marginTop: 16 }} type="inner" title="Sizing">
          <div
            className="p-3 px-0   flex  "
            onClick={() => setActive("design")}
            role="button"
          >
            {active == "design" && <Badge status="error" />}
            <Typography.Text className="ps-3">Design criteria</Typography.Text>
          </div>
          <Divider className="m-0 p-0" />
          <div
            className="p-3 px-0   flex  "
            onClick={() => setActive("process")}
            role="button"
          >
            {active == "process" && <Badge status="error" />}
            <Typography.Text className="ps-3">
              Process Conditions
            </Typography.Text>
          </div>
          <Divider className="m-0 p-0" />
          <div
            className="p-3 px-0   flex  "
            onClick={() => setActive("general")}
            role="button"
          >
            {active == "general" && <Badge status="error" />}
            <Typography.Text className="ps-3">
              General Configurations
            </Typography.Text>
          </div>
          <Divider className="m-0 p-0" />
          <div
            className="p-3 px-0   flex  "
            onClick={() => setActive("type")}
            role="button"
          >
            {active == "type" && <Badge status="error" />}
            <Typography.Text className="ps-3">
              Type Specific configuration
            </Typography.Text>
          </div>
        </Card>
        <Card style={{ marginTop: 16 }} type="inner" title="Weight analysis">
          <div
            className="p-3 px-0   flex "
            onClick={() => setActive("Design_specifications")}
            role="button"
          >
            {active == "Design_specifications" && <Badge status="error" />}
            <Typography.Text className="ps-3">
              Design specifications
            </Typography.Text>
          </div>
          <Divider className="m-0 p-0" />
          <div
            className="p-3 px-0   flex  "
            onClick={() => setActive("L/D")}
            role="button"
          >
            {active == "L/D" && <Badge status="error" />}
            <Typography.Text className="ps-3">
              L/D Range & Increment
            </Typography.Text>
          </div>
          <Divider className="m-0 p-0" />
          <div
            className="p-3 px-0   flex "
            onClick={() => setActive("Preferred")}
            role="button"
          >
            {active == "Preferred" && <Badge status="error" />}
            <Typography.Text className="ps-3">Preferred case</Typography.Text>
          </div>
        </Card>
        {sizing?.status > 0 && (
          <Card style={{ marginTop: 16 }} type="inner" title="Report">
            <Link href={`/report/${sizing?.id}`} target="_blank">
              <div className="p-3 px-0   flex justify-between  ">
                <Typography.Text className="ps-3">
                  Sizing report
                </Typography.Text>
                <Typography.Text>
                  <Badge status="error" className="me-2" /> <DownloadOutlined />{" "}
                  View
                </Typography.Text>
              </div>
            </Link>
            {sizing?.status == 3 && (
              <>
                {" "}
                <Divider className="m-0 p-0" />
                <Link href={`/report/${sizing?.id}?wa=true`} target="_blank">
                  <div className="p-3 px-0   flex justify-between  ">
                    <Typography.Text className="ps-3">
                      Weight analysis report
                    </Typography.Text>
                    <Typography.Text>
                      <Badge status="error" className="me-2" />
                      <DownloadOutlined /> View
                    </Typography.Text>
                  </div>
                </Link>{" "}
              </>
            )}
          </Card>
        )}
      </div>
    </Affix>
  );
};
