import Global from "../Settings/config.json";
export const preferredApi= {
    async load(apiClient,id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/preferred/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async save(apiClient,id,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/preferred/${id}`,data)
            .then((json) => {
                switch (json.status) {
                    case 201:
                        res = 201
                        break;
                }
            })
        return res;
    },
}