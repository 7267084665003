import React, { useEffect, useState } from 'react';
import useApiClient from '../../Settings/useApiClient';
import { planApi } from '../../API/planApi';
import Plan from '../../components/Plan';
import SidebarPlans from '../../components/SidebarPlans';
import { Button, Card, Flex, Skeleton, Space } from 'antd';

function Plans() {
  const [plans, setPlans] = useState([]);
  const [q, setQ] = useState("")
  const [months, setMonths] = useState("")
  const apiClient = useApiClient();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      load();
  }, [q,months]);
  const load=()=>{
    setLoading(true);
    planApi
      .plans(apiClient,q,months)
      .then((res) => {
        setPlans(res);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }
  
  return (
    <div className="mt-40 top-20 flex gap-4 items-start m-5 ">
      <SidebarPlans load={load} setQ={setQ} q={q} />
      <div className='grid w-full bg-white p-5'>
        <div className='flex justify-between items-center'>
          <strong className="m-5">■ plans</strong>
          <div className='flex gap-3'> 
            <Button className={`${months==1?'bg-gray-300':''}`} onClick={()=>setMonths(1)} >Monthly</Button>
            <Button  className={`${months==3?'bg-gray-300':''}`} onClick={()=>setMonths(3)}> Quarterly</Button>
            <Button className={`${months==6?'bg-gray-300':''}`} onClick={()=>setMonths(6)}>Semi-annually</Button>
            <Button className={`${months==12?'bg-gray-300':''}`} onClick={()=>setMonths(12)}>Yearly</Button>
            <Button className={`${months==''?'bg-gray-300':''}`} onClick={()=>setMonths("")}>All</Button>
             </div>
        </div>
        <div className="grid grid-cols-3 gap-6 w-5/6 m-auto ">
          
          {loading?
        <>
             <Card className='h-96 p-10'>
      <Skeleton active loading={true}>
        <Card.Meta
          title=""
          description=""
        />
      </Skeleton>
    </Card>
    <Card className='h-96 p-10'>
      <Skeleton active loading={true}>
        <Card.Meta
          title=""
          description=""
        />
      </Skeleton>
    </Card>
    <Card className='h-96 p-10'>
      <Skeleton active loading={true}>
        <Card.Meta
          title=""
          description=""
        />
      </Skeleton>
    </Card>
            
    </>
          
          :plans.map((p) => {
            return <Plan plan={p} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Plans;
