import React from 'react'
import { toFixed } from '../../helpers/Converts'

const DimensionsGeneral = ({res}) => {
  return (
     <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th colSpan={2}></th>
                      <th
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                        colSpan={2}
                      >
                        Si
                      </th>
                      <th
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                        colSpan={2}
                      >
                        Field
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {" "}
                        Separator Tag{" "}
                      </td>
                      <td
                        colSpan={4}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {res?.sizing?.separator_tag}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {" "}
                        Separator Type{" "}
                      </td>
                      <td
                        colSpan={4}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {res?.configuration?.configuration?.name}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {" "}
                        Diameter{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.BD75, 2)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        m{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        {toFixed(res.data?.BD75 / 0.3048, 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        ft{" "}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {" "}
                        Length{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        {toFixed(res.data?.BH75, 2)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        m{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        {toFixed(res.data?.BH75 / 0.3048, 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        ft{" "}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {" "}
                        L/D{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {" "}
                        {toFixed(res.data?.BH79, 2)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {" "}
                        Total Shell Volume{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.D83, 3)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        m<sub className="align-top">3</sub>
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.D83 * 35.31467, 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        ft<sub className="align-top">3</sub>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ border: "1px solid black", padding: "3px 8px" }}
                      >
                        {" "}
                        Total Volume{" "}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AA83, 3)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        m<sub className="align-top">3</sub>
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AA83 * 35.31467, 2)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        ft <sub className="align-top">3</sub>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0px",
                          textAlign: "center",
                          writingMode: "vertical-lr",
                          transform: "rotate(180deg)",
                        }}
                        rowSpan={5}
                      >
                        Light Liquid
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        LLL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.X71, 0)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.X71 / 25.4, 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        LL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AA71, 0)}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AA71 / 25.4, 2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        NL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AH71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AH71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        HL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AK71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AK71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        HHL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AN71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.AN71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0px",
                          textAlign: "center",
                          writingMode: "vertical-lr",
                          transform: "rotate(180deg)",
                        }}
                        rowSpan={5}
                      >
                        Heavy Liquid
                      </td>
    
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        LLL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.D71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.D71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        LL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.G71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.G71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        NL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.N71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.N71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        HL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.Q71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.Q71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        HHL
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.T71) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        mm
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        {toFixed(res.data?.T71 / 25.4,2) || "-"}
                      </td>
                      <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                        in.
                      </td>
                    </tr>
                  </tbody>
                </table>
  )
}

export default DimensionsGeneral