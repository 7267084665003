import React from 'react'
import { K32f, K33f, K34f } from '../../helpers/reportHelp'

const ProcessData = ({res,I40}) => {
  return (
   <table style={{ borderCollapse: "collapse", width: "100%" }}>
                 <thead>
                   <tr>
                     <th></th>
                     <th
                       style={{ border: "1px solid black", padding: "3px 8px" }}
                       colSpan={2}
                     >
                       Si
                     </th>
                     <th
                       style={{ border: "1px solid black", padding: "3px 8px" }}
                       colSpan={2}
                     >
                       Field
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Brometric Press
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K21[0].toFixed(3) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {" "}
                       bar
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K21[1].toFixed(2) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       psi
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Pressure
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K22[0].toFixed(2) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       barg
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K22[1].toFixed(1) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       psig
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Temperature
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K23[0].toFixed(0) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {" "}
                       °C
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K23[1].toFixed(0) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       °F
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Act. Gas Density
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K24[0].toFixed(3) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {" "}
                       kg/m<sup>3</sup>
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K24[1].toFixed(3) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       lb/ft<sup>3</sup>
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Gas Viscosity
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K25[0].toFixed(4) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {" "}
                       cP
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K25[1].toFixed(4) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       cP
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Gas Flowrate
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K26[0].toFixed(2) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {" "}
                       kg/h
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K26[1].toFixed(1) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       lb/h
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Act. L. Liq. Density
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K28[0].toFixed(2) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       kg/m<sup>3</sup>
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K28[1].toFixed(2) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       lb/ft<sup>3</sup>
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       L. Liq. Viscosity
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K29[0].toFixed(4) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       cP
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K29[1].toFixed(4) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       cP
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       L. Liq. Flowrate
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K30[0].toFixed(2) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       kg/h
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {res?.process?.K30[1].toFixed(1) ?? "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       lb/h
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       Act. H. Liq. Density
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {K32f(I40, res?.process?.K32)}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       kg/m<sup>3</sup>
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {(K32f(I40, res?.process?.K32) * 0.0624279606).toFixed(4) ||
                         "-"}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       lb/ft<sup>3</sup>
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       H. Liq. Viscosity
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {K33f(I40, res?.process?.K33)}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {" "}
                       cP
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {K33f(I40, res?.process?.K33)}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       cP
                     </td>
                   </tr>
                   <tr>
                     <th style={{ border: "1px solid black", padding: "3px 8px" }}>
                       H. Liq. Flowrate
                     </th>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {K34f(I40, res?.process?.K34)}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {" "}
                       kg/h{" "}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       {(K34f(I40, res?.process?.K34) * 2.20462).toFixed(1)}
                     </td>
                     <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                       lb/h
                     </td>
                   </tr>
                 </tbody>
               </table>
  )
}

export default ProcessData