import { ArrowRightOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { ProjectApi } from '../API/ProjectApi';
import useApiClient from '../Settings/useApiClient';
import useNotify from '../hooks/useNotify';
import { ParsError } from '../Tools/ParsError';
import { event_project_modal } from '../services/events';
import { HomeContext } from '../Context/HomeContext';

const LabelProject = ({
  project,
  loadSizings,
}) => {
  const {setProjectIdActive,projectIdActive}=useContext(HomeContext)
  
  const apiClient = useApiClient();
  const { openNotification } = useNotify();
  const deleteProject = () => {
    ProjectApi.delete(apiClient, project.id)
      .then((res) => {
        openNotification('Success', 'Project deleted successfully', 200);
        document.location.reload();
      })
      .catch((e) => {
        if (e?.response?.status === 422) {
          openNotification('Error', ParsError(e?.response));
        }
      });
  };
  useEffect(()=>{
    if(projectIdActive==project.id)
      loadSizings(projectIdActive);
  },[projectIdActive])
  const editProject = () => {
    event_project_modal(project.id);
  };
  const archiveProject = () => {
    ProjectApi.archive(apiClient, project.id)
      .then((res) => {
        openNotification('Success', 'Project archived successfully', 200);
        document.location.reload();
      })
      .catch((e) => {
        if (e?.response?.status === 422) {
          openNotification('Error', ParsError(e?.response));
        }
      });
  };
  const items = [
    {
      key: '1',
      label: <button onClick={editProject}>Edit</button>,
    },
    {
      key: '2',
      label: <button onClick={archiveProject}>Archive</button>,
    },
    {
      key: '3',
      label: (
        <button className="text-red-700" onClick={deleteProject}>
          Delete
        </button>
      ),
    },
  ];
  return (
    <li className="">
      <button
        type="button"
        className={`flex items-center w-full p-2 pb-3 mt-2 text-base text-gray-900 transition duration-75 rounded-lg group   ${
          projectIdActive == project.id ? 'bg-gray-100' : ''
        }  hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
        onClick={() => {
          setProjectIdActive(project.id);
         
        }}
      >
        {project?.id !== 0 ? (
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
            arrow
          >
            <MoreOutlined />
          </Dropdown>
        ) : null}
        <Typography.Text className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
          {`${project?.name}  ${project?.initials?"("+project?.initials+")" : ''}`}
        </Typography.Text>
        {projectIdActive == project.id && <ArrowRightOutlined />}
      </button>
    </li>
  );
};

export default LabelProject;
