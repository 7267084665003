import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useRef, useState } from "react";
import DesignCriteriaProject from "../components/DesignCriteriaProject";
import NotificationContext from "../Context/NotificationContext";
import useNotify from "../hooks/useNotify";
import { useNavigate } from "react-router-dom";
import ProjectForm from "../components/ProjectForm";
const ModalNewProject = ({ modalOpen, setModalOpen }) => {
  const { openNotification } = useNotify();
  const [loading, setLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const designCriteriaRef = useRef();
  const projectFormRef = useRef();
  const navigate = useNavigate();
  const sendData = () => {
    projectFormRef.current.submitForm();
  };

  // const goNext = () => {
  //   // document.location.href=`/sizing/${project.id}`
  //   navigate(`/sizing/${project.id}`);
  // };
  // const close = () => {
  //   setModalOpen(false);
  // };
  const onFinishProjectForm = (pid) => {
    designCriteriaRef.current.submitForm(pid);
  };
  const onFinishDesignCretria = (pid) => {
    navigate(`/sizing/${pid}`);
  };
  return (
    <Modal
      closeIcon={false}
      title={
        <div className="w-100 bg-gray-300 pt-2 pb-2 text-center ">
          New project
        </div>
      }
      footer={false}
      loading={loading}
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1000}
      destroyOnClose={true}
    >
      <div className="p-3">
        <section className="text-gray-700">
          <div className="container  mx-auto">
            <div className="flex flex-wrap  mb-2 ">
              <div className="w-full px-4 py-2  ">
                <ProjectForm
                  onFinish={onFinishProjectForm}
                  ref={projectFormRef}
                />
                <details className="mb-4" open={true}>
                  <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                    Design Criteria:
                  </summary>
                  <div className="relative overflow-x-auto  sm:rounded-lg p-1 py-0">
                    <DesignCriteriaProject
                      onFinish={onFinishDesignCretria}
                      ref={designCriteriaRef}
                    />
                  </div>
                </details>
                <div className="m-auto text-center w-full">
                  <Button
                    onClick={sendData}
                    className="  rounded-none  mt-5 bg-[--blue] text-white hover:bg-gray-900  font-medium  text-sm px-12 py-5 me-2 "
                  >
                    Save Project
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default ModalNewProject;
