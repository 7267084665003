import { Button, Checkbox, Form, Modal, Select, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useApiClient from "../Settings/useApiClient";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { weightApi } from "../API/weightApi";
import { ProjectContext } from "../Context/ProjectContext";
import { findMinimumCases, sortMinimumCases } from "../Tools/lib";
import { preferredApi } from "../API/preferredApi";

export const CaseStudySelect = () => {
  const { setDataInput, setActive,setCaseStudyShow } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [caseStudies, setCaseStudies] = useState([]);
  const [caseStudySelected, setCaseStudySelected] = useState(null);
  const [minimumWeight, setMinimumWeight] = useState(false);
  const { confirm } = Modal;

  const apiClient = useApiClient();
  const loadPreferredCase = () => {
    setLoading(true);
    preferredApi
      .load(apiClient, id)
      .then((r) => {
        setCaseStudySelected(r.selected_case);
        setTimeout(()=>{
          setMinimumWeight(r.minimum_case);

        },600)
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    weightApi
      .load(apiClient, id)
      .then((r) => {
        let _data = [];
        const dataSource = JSON.parse(r?.weights?.datasource ?? null) || [];
        dataSource?.map((d) => {
          _data.push({ value: d.id, label: d.id, ...d });
        });
        if(dataSource.length>0)
          setCaseStudyShow(true)
        setCaseStudies(_data);
        loadPreferredCase();

      })
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    if (minimumWeight) {
      const _caseStudies = findMinimumCases(caseStudies);

      if (caseStudySelected !== _caseStudies[0]?.id) setMinimumWeight(false);
    }
  }, [caseStudySelected]);

  const onChangeCaseStudy = (value) => {
    setCaseStudySelected(value);
  };
  const minimumWeightChangeHandle = (e) => {
    setMinimumWeight(e.target.checked);

    if (e.target.checked) {
      const _caseStudies = findMinimumCases(caseStudies);
      setCaseStudySelected(_caseStudies[0].value);
    }
  };
  const saveData = () => {
    setLoading(true);
    const data = {
      minimum_case: minimumWeight,
      selected_case: caseStudySelected,
    };
    preferredApi
      .save(apiClient, id, data)
      .then((r) => {
        const _caseStudies = sortMinimumCases(caseStudies);
        setDataInput({
          section: "config",
          Nominal: _caseStudies[caseStudySelected-1].Nominal,
        });
        setActive("type");
        console.log(caseStudies,222)
      })
      .catch(e=>console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };
  const submit = () => {
    confirm({
      title: "Three Phase Separator Calculations",
      okButtonProps:{style:{backgroundColor:"#1677ff"}},
      icon: <ExclamationCircleFilled />,
      content: `Are you ready to run the selected case (${caseStudySelected})?`,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        saveData();
      },
      onCancel() {},
    });
  };
  return (
    <>
      <div className="mb-5 ">
        <Typography.Title level={4} className="title-1 mt-5">
          Preferred case
        </Typography.Title>
      </div>

      <Form onFinish={submit}>
        <div className="grid grid-cols-4 gap-3 items-end">
          <div className="grid  ">
            <Typography.Text className="important ">Case study</Typography.Text>
            <Select
              loading={loading}
              placeholder={"Select"}
              required
              
              value={caseStudySelected}
              onChange={onChangeCaseStudy}
              options={caseStudies}
            />
          </div>
          <div className="flex col-span-2 bg-gray-300 p-2 h-9 rounded-md">
            <Checkbox
              className="w-full text-nowrap"
              checked={minimumWeight}
              onChange={minimumWeightChangeHandle}
            >
              Minimum weight
            </Checkbox>
            <div className="text-end w-full px-5 text-[#234E70] text-nowrap">
              Case {caseStudySelected}:{" "}
              {caseStudies[caseStudySelected-1]?.Separator_weight_kg} kg
            </div>
          </div>
          <Button
            htmlType="submit"
            disabled={loading || !caseStudySelected}
            loading={loading}
            className=" h-9 rounded-md text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8  "
          >
            Generate Report
          </Button>
        </div>
      </Form>
    </>
  );
};
