import React from "react";
import { toFixed } from "../../helpers/Converts";
import { list_of_material, list_of_material2 } from "../../assets/consts";

const MaterialDesign = ({ res }) => {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        marginTop: 25,
      }}
    >
      <thead className="font-bold">Material & Design Condition</thead>
      <tbody>
        <tr>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            Design Press. / Temp.
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            {toFixed(res?.design_specification?.design_pressure, 1) || "-"}
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            barg
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            {toFixed(res?.design_specification?.design_temperature, 0) || "-"}
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>°C</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            Corr. Allo. / Joint Eff.
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            {toFixed(res?.design_specification?.corrosion_allowance, 0) || "-"}
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>mm</td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            {toFixed(res?.design_specification?.joint_efficiency, 0) || "-"}
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>%</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            Allow. Stress / Density
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            {toFixed(res?.design_specification?.allowable_stress, 0) || "-"}
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>Mpa</td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            {toFixed(res?.design_specification?.material_density, 0) || "-"}
          </td>
          <td style={{ border: "1px solid black", padding: "3px 8px" }}>
            kg/m<sup>3</sup>
          </td>
        </tr>
        <tr>
          <td colSpan={1} style={{ border: "1px solid black", padding: "3px 8px" }}>
          Material Specifications
          </td>
          <td  colSpan={4} style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res?.design_specification?.material_specifications?list_of_material2?.[res?.design_specification?.material_specifications]?.Material:'-'}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MaterialDesign;
