import { Card } from 'antd'
import React, { useState } from 'react'
import CaseChart from './CaseChart'
import CaseStudy from './CaseStudy'
import { useParams } from 'react-router-dom'

const ResultCaseStudy = () => {
    const {id}=useParams()
    const [maximizeSchemaStyle, setMaximizeSchemaStyle] = useState('');
    const maximizeSchema = () => {
        if (maximizeSchemaStyle) setMaximizeSchemaStyle('');
        else
          setMaximizeSchemaStyle(
            '!fixed !w-full left-0 top-28 !h-[70vw] overflow-auto overflow-x-hidden'
          );
      };
  return (
    <Card title={'Case Study'}  className={` ${maximizeSchemaStyle} bg-white `} extra={
        <img
          src={'/images/maximize.svg'}
          role="button"
          onClick={maximizeSchema}
          className={`w-7  hover:scale-125 rounded-sm `}
        />
      }>
        <div className={`${maximizeSchemaStyle?'grid grid-cols-2':''} gap-10`}>
    <label className="text-start font-bold">
    <CaseChart sizing_id={id} />
    </label>
    
    
    <label className="text-start font-bold mt-5">
    <CaseStudy maximize={maximizeSchemaStyle?true:false}  sizing_id={id} />
    </label>
    </div>
   
  </Card>

  )
}

export default ResultCaseStudy