import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case7_1({info}) {
    return (
        <>
          
          <div className="HHLL grid gap-x-2 text-start leading-4">
            <div className='flex items-center justify-between gap-1'><span>HHLL</span><span>{toFixed(info.AN71,0)}</span></div>
                                          <div className='flex items-center justify-between gap-1'><span>HLL</span><span>{toFixed(info.AK71,0)}</span></div>
                                          <div className='flex items-center justify-between gap-1'><span>NLL</span><span>{toFixed(info.AH71,0)}</span></div>
                                          <div className='flex items-center justify-between gap-1'><span>LLL</span><span>{toFixed(info.AA71,0)}</span></div>
                                          <div className='flex items-center justify-between gap-1'><span>LLLL</span><span>{toFixed(info.X71,0)}</span></div>
          </div>
     
          <div className="L-7">
            <label>L </label>{toFixed(info.BH75,3)}
          </div>
          <div className="D-7">
            <label>D </label>{toFixed(info.BD75,3)}
          </div>
          <div className="hd-7">
            <label>H<sub className="align-sub bg-white ">d</sub> </label>{info.BH71}
          </div>
          <div className="hbn-7">
            <label>H<sub className="align-sub bg-white ">bn</sub> </label>{info.BD71}
          </div>
        </>)
}

export default Case7_1