import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case3_6({info}) {
    return (
        <>
         
         <div className="HHLL-H grid text-start leading-4">
        <div className='flex items-center justify-between gap-1'><span>HHLL</span><span>{toFixed(info.AN71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>HLL</span><span>{toFixed(info.AK71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>NLL</span><span>{toFixed(info.AH71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>LLL</span><span>{toFixed(info.AA71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>LLLL</span><span>{toFixed(info.X71,0)}</span></div>
             </div>
             <div className="HHLIL-H text-start grid leading-4">
                <div className='flex items-center justify-between gap-1 text-start'><span>HHLIL</span><span>{toFixed(info.T71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>HLIL</span><span>{toFixed(info.Q71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>NLIL</span><span>{toFixed(info.N71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>LLIL</span><span>{toFixed(info.G71,0)}</span></div>
                                            <div className='flex items-center justify-between gap-1'><span>LLLIL</span><span>{toFixed(info.D71,0)}</span></div>
      </div>
      <div className="L-H">
        <label>L </label>
        {toFixed(info.BH75, 3)}
      </div>
      <div className="D-H">
        <label>D </label>
        {toFixed(info.BD75, 3)}
      </div>

      <div className="hv-H text-center">
        <label>
          H<sub className="align-sub ">v</sub>
        </label>
        <br />
        {toFixed(info.AR71, 0)}
      </div>
        </>)
}

export default Case3_6