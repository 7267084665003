/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

const Header = ({res,sheet}) => {
  return (
        <thead>
            <tr className='w-full'>
              <td className='w-1/5'>
                <img
                  style={{
                    aspectRatio: "16 / 16",
                    maxWidth: 50,
                    maxHeight: 50,
                    objectFit: "contain",
                  }}
                  src={res["project"]["logo1"]}
                />
              </td>
            
              <td  colSpan={3} className="text-center p-5 pb-0 w-3/5">
                <h1 className="!text-[16px] font-bold text-center">
                  Three-Phase Seperator <br />
                  Sizing Report
                </h1>
              </td>
             
              <td className='w-1/5'>
                <img
                  style={{
                    marginLeft: "auto",
                    aspectRatio: "16 / 16",
                    maxWidth: 50,
                    maxHeight: 50,
                    objectFit: "contain",
                  }}
                  src={res["project"]["logo2"]}
                />
              </td>
            </tr>
            <tr className=" text-base">
              <td colSpan={5} className="text-center">
                <div
                  style={{
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "10% 50% 10% 10%",
                    columnGap: "1%",
                    justifyContent: "space-around",
                  }}
                >
                  <span className="border-b p-1">Employer</span>
                  <span className="border-b p-1">
                    {(res?.project?.client || res?.project?.client=="null") ? "-":res?.project?.client}
                  </span>
                  <span className="border-b p-1">PROJ No</span>
                  <span className="border-b p-1">
                    {(res?.project?.project_number || res?.project?.project_number=="null") ? "-":res?.project?.project_number}
                  </span>
                </div>
              </td>
            </tr>
            <tr className="head">
              <td colSpan={5} className="text-center">
                <div
                  style={{
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "10% 50% 10% 10%",
                    columnGap: "1%",
                    justifyContent: "space-around",
                  }}
                >
                  <span className="border-b p-1">PROJECT</span>
                  <span className="border-b p-1">
                    {res?.project?.name + " (" + res?.project?.initialize + ")" ??
                      "-"}
                  </span>
                  <span className="border-b p-1">CALC No</span>
                  <span className="border-b p-1">
                    {" "}
                    {res?.sizing?.calc_no ?? "-"}
                  </span>
                </div>
              </td>
            </tr>
            <tr className="head">
              <td colSpan={5} className="text-center">
                <div
                  style={{
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "10% 50% 10% 10%",
                    columnGap: "1%",
                    justifyContent: "space-around",
                  }}
                >
                  <span className="border-b p-1">SUBJECT</span>
                  <span className="border-b p-1">
                    Sizing repotr of {res.sizing.separator_tag}
                  </span>
                  <span className="border-b p-1">SHEET</span>
                  <span className="border-b p-1">
                    {sheet} {"    "} of {} 3
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="text-center mt-3">
                <table className="w-full">
                  <tr>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      REVISION
                    </td>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      DATE
                    </td>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      Prepared
                    </td>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      Checked
                    </td>
    
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      Approved
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      {res?.sizing?.revision ?? "-"}
                    </td>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      {res?.sizing?.date ?? "-"}
                    </td>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      {res?.sizing?.approved ?? "-"}
                    </td>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      {res?.sizing?.prepared ?? "-"}
                    </td>
                    <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                      {res?.sizing?.checked ?? "-"}
                    </td>
                  </tr>
                </table>
              </td>
              <td></td>
              <td></td>
            </tr>
          </thead>
  )
}

export default Header