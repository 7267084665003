import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useRef, useState } from "react";
import DesignCriteriaEditProject from "../components/DesignCriteriaEditProject";
import ProjectEditForm from "../components/ProjectEditForm";
const ModalEditProject = ({ modalOpen, setModalOpen, project_id }) => {
  const [loading, setLoading] = useState(false);
  const designCriteriaRef = useRef();
  const projectFormRef = useRef();
  const sendData = () => {
    projectFormRef.current.submitForm();
  };


  const close = () => {
    setModalOpen(false);
  };
  const onFinishProjectForm = () => {
    designCriteriaRef.current.submitForm();
  };
  const onFinishDesignCretria = () => {
    close();
  };
  return (
    <Modal
      closeIcon={false}
      title={
        <div className="w-100 bg-gray-300 pt-2 pb-2 text-center ">
          Project Information
        </div>
      }
      footer={false}
      loading={loading}
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1000}
      destroyOnClose={true}
    >
      <div className="p-3">
        <section className="text-gray-700">
          <div className="container  mx-auto">
            <div className="flex flex-wrap  mb-2 ">
              <div className="w-full px-4 py-2  ">
                <ProjectEditForm project_id={project_id}
                  onFinish={onFinishProjectForm}
                  ref={projectFormRef}
                />
                <details className="mb-4" open={true}>
                  <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                    Design Criteria:
                  </summary>
                  <div className="relative overflow-x-auto  sm:rounded-lg p-1 py-0">
                    <DesignCriteriaEditProject
                      project_id={project_id}
                      onFinish={onFinishDesignCretria}
                      ref={designCriteriaRef}
                    />
                  </div>
                </details>
                <div className="m-auto text-center w-full">
                  <Button
                    onClick={sendData}
                    className="  rounded-none  mt-5 bg-[--blue] text-white hover:bg-gray-900  font-medium  text-sm px-12 py-5 me-2 "
                  >
                    Update Project
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default ModalEditProject;
