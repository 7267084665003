import React from 'react'
import { toFixed } from '../../helpers/Converts'
import { AL39f } from '../../helpers/reportHelp'

const DimensionsCompartments = ({res,I40}) => {
  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{ border: "1px solid black", padding: "3px 8px" }}
                    colSpan={2}
                  >
                    Si
                  </th>
                  <th
                    style={{ border: "1px solid black", padding: "3px 8px" }}
                    colSpan={2}
                  >
                    Field
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={5}
                    style={{ border: "1px solid black", padding: "3px 8px" }}
                  >
                    Recom. Nozzle Size{" "}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    Feed (d<sub className="align-sub">F</sub>)
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {AL39f(I40, res.data?.AV45, res.data?.AT45)}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {AL39f(I40, res.data?.AV45, res.data?.AT45) * 25.4}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    Gas (d<sub className="align-sub">G</sub>)
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.Y66 * 25.4) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.Y66) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    Light Liq. (d<sub className="align-sub">LL</sub>)
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AB66 * 25.4) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AB66) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    Heavy Liq. (d<sub className="align-sub">HL</sub>)
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AE66 * 25.4) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AE66) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "3px 8px",
                      height: 34
                    }}
                    colSpan={5}
                  ></td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    H<sub className="align-sub">v</sub>
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AR71) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AR71 / 304.8) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    H boot
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AV75) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AV75 / 304.8, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    D boot
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AR75) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    m
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AR75 / 0.3048, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    H W/Bucket
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AV71) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AVR71 / 25.4, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    H bn
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.BD71) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.BD71 / 25.4, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    H D
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.BH71, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    mm
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.BH71 / 25.4, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "3px 8px",
                      height: 1,
                    }}
                    colSpan={5}
                  ></td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    L1
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AR79, 1) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    m
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AR79 / 3048, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    L2
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AV79, 1) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    m
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AV79 / 3048, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    L3
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AZ79, 1) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    m
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.AZ79 / 3048, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    L4
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.BD79, 1) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    m
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    {toFixed(res.data?.BD79 / 3048, 2) || "-"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px 8px" }}>
                    ft
                  </td>
                </tr>
              </tbody>
            </table>
  )
}

export default DimensionsCompartments