import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from '../Context/ProjectContext';
import { sizingApi } from '../API/sizingApi';
import useApiClient from '../Settings/useApiClient';
import { useParams } from 'react-router-dom';
import { Card, Spin } from 'antd';

import { fetchSchema, fetchSchemaData } from '../Tools/tools';

const Schema = () => {
  const [I40, setI40] = useState(0);
  const [loading, setLoading] = useState(false);
  const [demister, setDemister] = useState(false);
  const [deverter, setDeverter] = useState(false);
  const [coalescer, setCoalescer] = useState(false);
  const [info, setInfo] = useState({});
  const apiClient = useApiClient();
  const { id } = useParams();
  const [maximizeSchemaStyle, setMaximizeSchemaStyle] = useState('');

  const { sizing, setSizingLoading, sizingLoading } =
    useContext(ProjectContext);
  useEffect(() => {
    if (!id) return;
    if (sizing?.status == 0) return;
    if (sizingLoading == -1) return;
    setLoading(true)
    sizingApi
      .schema(apiClient, id)
      .then((res) => {

        if (!res) return;
        if(res.data==[]) return
        if(!res['configuration']) return
        setInfo(res.data)
        setI40(res['configuration'].configuration_id);
        setDemister(res['configuration'].Demister);
        setDeverter(res['configuration'].Diverter);
        setCoalescer(res['configuration'].Coalescer);
      }).catch(e=>console.log(e))
      .finally(() => setLoading(false));
  }, [sizing,sizingLoading]);
  useEffect(() => {
    if (!maximizeSchemaStyle) return;
    setLoading(true)
       sizingApi
      .schema(apiClient, id)
      .then((res) => {

        if (!res) return;
        if(res.data==[]) return
        if(!res['configuration']) return
        setInfo(res.data)
        setI40(res['configuration'].configuration_id);
        setDemister(res['configuration'].Demister);
        setDeverter(res['configuration'].Diverter);
        setCoalescer(res['configuration'].Coalescer);
      }).catch(e=>console.log(e))
      .finally(() => setLoading(false));
  }, [maximizeSchemaStyle]);

  const schema = () => {
   return fetchSchema(I40,demister,deverter,coalescer)
   
  };
  const maximizeSchema = () => {
    if (maximizeSchemaStyle) setMaximizeSchemaStyle('');
    else
      setMaximizeSchemaStyle(
        '!fixed !w-full left-0 top-28 !h-[70vw] overflow-auto overflow-x-hidden  z-50 '
      );
  };


  if (loading) return <Spin />;
  if (!I40) return;
  return (
    <Card
      title={'Schematic view: '+sizing?.separator_tag}
      className={`result ${maximizeSchemaStyle} bg-white `}
      extra={
        <img
          src={'/images/maximize.svg'}
          role="button"
          onClick={maximizeSchema}
          className={`w-7  hover:scale-125 rounded-sm `}
        />
      }
    >
      <div className={`relative  grid ${maximizeSchemaStyle?'h-[73vh] overflow-auto':'h-[300px]'} justify-items-center `}>
        {/* <div className="absolute top-0 aspect-[16/9]   h-[700px] w-[500px] !-right-14"> */}
        <div
          className={`absolute top-0 aspect-[16/9]  ${
            maximizeSchemaStyle === ''
              ? [1, 2, 7].find((x) => x === I40)
                ? '!h-[300px]'
                : 'h-full  w-full'
              : [1, 2, 7].find((x) => x === I40)
              ? '!w-[900px] !h-[600px]  '
              : '!w-[900px] '
          } m-auto`}
        >
          <img src={`${schema()}`} className="m-auto h-full " />
          {maximizeSchemaStyle != '' && fetchSchemaData(info,I40,demister,deverter,coalescer)}
        </div>
      </div>
    </Card>
  );
};

export default Schema;
