import { event_change_data } from '../services/events';


export const Q_h_Liq = () => {


  const I40 = parseFloat(localStorage.getItem('I40'));
  const R34 = parseFloat(localStorage.getItem('R34'));
  const R32 = parseFloat(localStorage.getItem('R32'));
  const V35 = parseFloat(localStorage.getItem('V35'));
  const K35 = parseFloat(localStorage.getItem('K35'));
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
  } else {
      return (R34 / R32) * 0.028316846592 * (V35 ? K35 : 1);
  }
  };

  const result=calculate()||0;
  const D62=localStorage.getItem('D62'); 
  if(D62==  result ) return;
  localStorage.setItem('D62', result);
  event_change_data('Q_h_orange');
};

export const Q_l_Liq = () => {
  const R30 = parseFloat(localStorage.getItem('R30'));
  const R28 = parseFloat(localStorage.getItem('R28'));
  const R35 = parseFloat(localStorage.getItem('R35'));
  const K35 = parseFloat(localStorage.getItem('K35'));
  // 
  const result = (((R30 / R28) * 0.028316846592) * (R35 ? K35 : 1)) || 0;

  const H62=localStorage.getItem('H62');
  if(H62==  result ) return;
  localStorage.setItem('H62', result);
  event_change_data('Q_l_orange');
};
export const Q_g = () => {
  const R26 = parseFloat(localStorage.getItem('R26'));
  const R24 = parseFloat(localStorage.getItem('R24'));
  const O35 = parseFloat(localStorage.getItem('O35'));
  const K35 = parseFloat(localStorage.getItem('K35'));


  const factor = O35 ? K35 : 1;
  const result = ((R26 / R24) * 0.028316846592 * factor)||0;
  const L62=localStorage.getItem('L62');
  if(L62==  result ) return;
  localStorage.setItem('L62', result);
  event_change_data('Q_g_orange');
};

export const u_t = () => {
  // alert(4)
  const AW40=parseFloat(localStorage.getItem('AW40'));
  const  R28=parseFloat(localStorage.getItem('R28'));
  const   R24=parseFloat(localStorage.getItem('R24'));
  
  function calculate() {
    return AW40 * Math.sqrt((R28 - R24) / R24) * 0.3048;
  }
  const result = calculate(AW40, R28, R24) ||0;
  const P62=localStorage.getItem('P62');
  if(P62==  result ) return;
  localStorage.setItem('P62', result );
  event_change_data("u_t_orange")
};


export const u_h = () => {
  const I40=parseFloat(localStorage.getItem('I40'));
  const  BA40=parseFloat(localStorage.getItem('BA40'));
  const   R32=parseFloat(localStorage.getItem('R32'));
  const   R28=parseFloat(localStorage.getItem('R28'));
  const   R29=parseFloat(localStorage.getItem('R29'));
  function calculate() {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else {
      return BA40 * (R32 - R28) / R29 * 2.54;
    }
  }
  const res = calculate(I40, BA40, R32, R28, R29) ||0;
  const X62=localStorage.getItem('X62');
  if(X62==  res ) return;
  localStorage.setItem('X62', res );
  event_change_data("u_h_orange")
};


export const u_l = () => {
  const   I40=parseFloat(localStorage.getItem('I40'));
  const   BA40=parseFloat(localStorage.getItem('BA40'));
  const   R32=parseFloat(localStorage.getItem('R32'));
  const   R28=parseFloat(localStorage.getItem('R28'));
  const   R33=parseFloat(localStorage.getItem('R33'));

  function calculate() {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else {
      return BA40 * (R32 - R28) / R33 * 2.54;
    }
  }
  const res = calculate(I40, BA40, R32, R28, R33) ||0;
  

  const AB62=localStorage.getItem('AB62');
  if(AB62==  res ) return;
  localStorage.setItem('AB62', res );
  event_change_data("u_l_orange")
};







export const L_3= () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));

  const calculate = () => {
    if (I40 == 6) {
      let value = Math.max(BF45 / 12, 12 * 0.0254);
      return Math.round(value * 100) / 100;
  } else {
      return null;
  }
  }
  let result = calculate() || 0;
  const BD62=localStorage.getItem('BD62');
  if(BD62==  result ) return;
  localStorage.setItem('BD62', result);
  event_change_data('L_3_orange');
}
export const L_4= () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const _997_1000 = parseFloat(localStorage.getItem('997_1000'));

  const calculate = () => {
    if (I40 == 6) {
      return _997_1000;
  } else {
      return null;
  }
  }
  let result = calculate() || 0;
  const BH62=localStorage.getItem('BH62');
  if(BH62==  result ) return;
  localStorage.setItem('BH62', result);
  event_change_data('L_4_orange');
}
export const H_a= () => {
  const AK45 = parseFloat(localStorage.getItem('AK45'));
  const D66=localStorage.getItem('D66');
  if(D66==  AK45 ) return;

  localStorage.setItem('D66', AK45);
  event_change_data('H_a_orange');
}
export const W_d= () => {
  const AN45 = parseFloat(localStorage.getItem('AN45'));
  const G66=localStorage.getItem('G66');
  if(G66==   Math.round(AN45) ) return;

  localStorage.setItem('G66', Math.round(AN45));
  event_change_data('W_d_orange');
}
export const H_v=()=>{
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AK40 = parseFloat(localStorage.getItem('AK40'));
  const AB45 = parseFloat(localStorage.getItem('AB45'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));

  const calculate = () => {
    if (I40 == 1 || I40 == 2 || I40 == 7) {
      return null;
  } else if (I40 == 3 || I40 == 4 || I40 == 5 || I40 == 6 || I40 == 8) {
    const maxValue = Math.max((BF45 / 0.3048) * AK40, AB45 == 1 ? 2 : 1);
    return maxValue * 304.8;
  }
  }
  let result = calculate() || 0;

  const P66=localStorage.getItem('P66');
  if(P66== result ) return;
  localStorage.setItem('P66',  result);
  event_change_data('H_v_orange');
}
export const D_f_c= () => {
  const AR45 = parseFloat(localStorage.getItem('AR45'));

  const S66=localStorage.getItem('S66');
  if(S66==  AR45) return;

  localStorage.setItem('S66',parseFloat(AR45));
  event_change_data('D_f_c_orange');
}

export const D_f_in2= () => {
  const AV45 = parseFloat(localStorage.getItem('AV45'));

  const W66=localStorage.getItem('W66');
  if(W66==  Math.round(AV45)) return;


  localStorage.setItem('W66', Math.round(AV45));
  event_change_data('D_f_in2_orange');
}
export const HLL_in_Boot= () => {
  const AX45 = parseFloat(localStorage.getItem('AX45'));

  const AM66=localStorage.getItem('AM66');
  if(AM66==   AX45) return;


  localStorage.setItem('AM66', AX45);
  event_change_data('HLL_in_Boot_orange');
}
export const HHL_in_Boot= () => {
  const BB45 = parseFloat(localStorage.getItem('BB45'));
  const AW28 = parseFloat(localStorage.getItem('AW28'));
   let result= Math.max(BB45 / 0.3048, AW28 / 304.8);
   result=result||0;


   
  const AP66=localStorage.getItem('AP66');
  if(AP66==  result) return;


  localStorage.setItem('AP66', result);
  event_change_data('HHL_in_Boot_orange');
}


export const H_boot= () => {
  const AM66 = parseFloat(localStorage.getItem('AM66'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  let result= AN50 / 304.8 + AM66;

  
  const AV66=localStorage.getItem('AV66');
  if(AV66==  result) return;


  localStorage.setItem('AV66', result);
  event_change_data('H_Boot_orange');
}