import React, {  useContext, useEffect, useState } from 'react';
import {
  FolderAddOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Flex, Form, Menu, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import ModalNewProject from '../modals/ModalNewProject';
import { ProjectContext } from '../Context/ProjectContext';
import { HomeContext } from '../Context/HomeContext';
import ModalEditProject from '../modals/ModalEditProject';
const items = [
  {
    label: 'Filter',
    icon: <img src="/images/filter.svg" />,
    key: 'SubMenu',
    children: [
      { label: 'Label', key: 'label' },
      { label: 'Separator Tag', key: 'separator_tag' },
      { label: 'Date Created', key: 'created_at' },
      { label: 'Date Edited', key: 'updated_at' },
    
    ],
  },
];
const Toolbar = ({subscription,billboard,setOrderBy,sortBy,setSortBy,sendQuery}) => {
  const {project_id}=useParams();
  const {projectIdActive}=useContext(HomeContext)
  const [query, setQuery] = useState("")
  const [sizing, setSizing] = useState(true);
  const [weightAnalysis, setWeightAnalysis] = useState(true);
  const [projectId, setProjectId] = useState(null);
  const [inProgress, setInProgress] = useState(true);
  const [current, setCurrent] = useState('created_at');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  useEffect(()=>{
    setSizing(billboard.sizings)
    setWeightAnalysis(billboard.weights)
    setInProgress(billboard.inprogress)
  },[billboard])
  const onClickSort = (e) => {
    setOrderBy(e.key)
    setCurrent(e.key);
  };
  useEffect(() => {
    if (modalOpen === false) setProjectId(null);
  }, [modalOpen]);
  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('project_modal', (e) => {
        setProjectId(e?.detail?.id);
        setModalEditOpen(true);
      });
    }
  }, []);
  const newSizing = () => {};
  const sortChange=()=>{
    if(sortBy=="desc")
    setSortBy("asc")
    else
    setSortBy("desc")
  }
  const onSetQuery=()=>{
    sendQuery(query)
  }
  return (
    <div className="grid z-20 fixed w-full grid-cols-12 content-between  items-center container-fluid px-4 m-auto gap-2 bg-[#fafafa] ">
      <div className="col-span-2 flex  w-100 text-center align-center justify-start gap-2 top-20 mt-14">
        <Button
          type="primary"
          disabled={subscription?false:true}
          size='small'
          onClick={() => setModalOpen(true)}
          className="flex items-center disabled:text-gray-400   mb-5 mt-2 text-white !bg-[#234E70] hover:bg-gray-900  font-medium  text-[12px] px-2 py-4  "
        >
          <FolderAddOutlined style={{ fontSize: 20 }} className="" />
          New Project
        </Button>
        <Button
          type="primary"
          disabled={(subscription && projectIdActive)?false:true}
          size='small'
          onClick={()=>{document.location.href=`/sizing/${projectIdActive}`}}
          className="flex items-center   mb-5 mt-2 text-[#234E70] bg-white border-[#234E70] hover:bg-gray-900  font-medium  text-[12px] px-2 py-4 "
        >
          <PlusOutlined style={{ fontSize: 15 }} className="" />
          New Sizing
        </Button>
      </div>
      <div className="col-span-8   text-start w-full  top-20 mt-14 items-end">
        <div className="grid grid-cols-2 justify-between items-center">
          <div>
            <Flex className="font-bold " >
              <Tag
                icon={<img src="/images/sizing.svg" />}
                className="text-[#3C3F8D] border-0 flex   gap-2 items-center"
              >
                Sizing: {sizing}
              </Tag>
              <Tag
                icon={<img src="/images/weight.svg" />}
                className="text-[#44A913]  border-0 flex  gap-2 items-center"
              >
                Weight Analysis: {weightAnalysis}
              </Tag>
              <Tag
                icon={<img src="/images/progress.svg" />}
                className="text-[#D95A36]  border-0 flex  gap-2 items-center"
              >
                In progress: {inProgress}
              </Tag>
            </Flex>
          </div>
          <div className="w-full flex text-end items-center justify-end gap-3 ">
          {/* {tag && <Tag>{tag}</Tag>} */}
            <Menu
              onClick={onClickSort}
              selectedKeys={[current]}
              // className="!p-0 m-0 flex bg-white "
              mode="horizontal"
              defaultOpenKeys={["created_at"]}
              items={items}
              // style={{
              //   border: 0,
              //   background: 'white',padding:0,
              //   color: 'black',
              //   display: 'flex',
              // }}
            />
            
            <Button className="bg-white w-10 p-0 border-0 h-11" onClick={sortChange}>
              <img src={sortBy=="desc"?"/images/filterZ.svg":"/images/filterA.svg"} />
            </Button>
          </div>
        </div>
      </div>

      <div className="col-span-2   w-100 text-center  top-20 mt-14">
        <Form onFinish={onSetQuery} className="w-100 mx-auto ">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
       
          <div className="relative flex items-center">
        
            <input
              type="search"   disabled={subscription?false:true}
              id="default-search"
              className="block w-full p-3 ps-3 text-sm text-gray-900 border border-gray-300  bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search anything ..."
              value={query}
              onChange={(e)=>setQuery(e.target.value)}
              
            />
            <button
              type="submit"   disabled={subscription?false:true}
              className="text-white absolute end-0 h-full  bg-[#234E70] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium  text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
               <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </button>
           
          </div>
        </Form>
      </div>
      {modalOpen && (
        <ModalNewProject
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
       {modalEditOpen && (
        <ModalEditProject
          modalOpen={modalEditOpen}
          setModalOpen={setModalEditOpen}
          project_id={projectId}
        />
      )}
    </div>
  );
};

export default Toolbar;
