import { Affix, Card } from 'antd';
import React, { useContext } from 'react';
import Table2 from './tables/Table2';
import { ProjectContext } from '../Context/ProjectContext';
import Table5 from './tables/Table5';
import Table1 from './tables/Table1';
import Table3 from './tables/Table3';
import Schema from './Schema';
import ResultCaseStudy from './ResultCaseStudy';
import {  CloseOutlined } from '@ant-design/icons';

const ResultSizing = () => {
  const [top, setTop] = React.useState(120);
  const { tipTableNumber,  caseStudyShow,setTipTableNumber } =
    useContext(ProjectContext);

  return (
    <Affix offsetTop={top} className="ms-3 me-3">
      <div className=" h-[85vh]  overflow-y-auto overflow-x-hidden">
        {[1, 2, 3, 5].find((e) => e == tipTableNumber?.id) && (
          <Card title={'Details'} extra={<CloseOutlined onClick={()=>setTipTableNumber(0)} />}>
            {tipTableNumber?.id == 1 && <Table1 refresh={Math.random()} />}
            {tipTableNumber?.id == 2 && <Table2 />}
            {tipTableNumber?.id == 3 && <Table3 refresh={Math.random()} />}
            {tipTableNumber?.id == 5 && (
              <Table5 params={tipTableNumber.params} />
            )}
          </Card>
        )}
        <Schema />
        {caseStudyShow && 
        <ResultCaseStudy />
        }
      </div>
    </Affix>
  );
};

export default ResultSizing;
