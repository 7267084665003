import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Input, Tooltip, Upload } from 'antd';
import Link from 'antd/es/typography/Link';
import Typography from 'antd/es/typography/Typography';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useNotify from '../hooks/useNotify';
import { UnitSelect } from './UnitSelect';
import Form from 'antd/es/form/Form';
import { processConditionApi } from '../API/processConditionApi';
import useApiClient from '../Settings/useApiClient';
import { ProjectContext } from '../Context/ProjectContext';
import { tip_L_liq_Gas_Particle_Dia } from '../assets/consts';

const ProcessConditions = () => {
  const { sizing, active, setActive, updateSizing, setTipTableNumber } =
    useContext(ProjectContext);

  const { openNotification } = useNotify();
  const [sitAtmPress, setSitAtmPress] = useState();
  const [sitAtmPressUnit, setSitAtmPressUnit] = useState();
  const [actLLiqDensity, setActLLiqDensity] = useState();
  const [actLLiqDensityUnit, setActLLiqDensityUnit] = useState();
  const [pressure, setPressure] = useState();
  const [pressureUnit, setPressureUnit] = useState();
  const [lLiqViscosity, setLLiqViscosity] = useState();
  const [lLiqViscosityUnit, setLLiqViscosityUnit] = useState();
  const [temperature, setTemperature] = useState();
  const [temperatureUnit, setTemperatureUnit] = useState();
  const [lLiqFlowrate, setLLiqFlowrate] = useState();
  const [lLiqFlowrateUnit, setLLiqFlowrateUnit] = useState();
  const [actGasDensity, setActGasDensity] = useState();
  const [actGasDensityUnit, setActGasDensityUnit] = useState();
  const [lLiq_HLiqParticleDia, setLLiq_HLiqParticleDia] = useState();
  const [lLiq_HLiqParticleDiaUnit, setLLiq_HLiqParticleDiaUnit] = useState();
  const [gasViscosity, setGasViscosity] = useState();
  const [gasViscosityUnit, setGasViscosityUnit] = useState();
  const [actHLiqDensity, setActHLiqDensity] = useState();
  const [actHLiqDensityUnit, setActHLiqDensityUnit] = useState();
  const [gasFlowrate, setGasFlowrate] = useState();
  const [gasFlowrateUnit, setGasFlowrateUnit] = useState();
  const [hLiqViscosity, setHLiqViscosity] = useState();
  const [hLiqViscosityUnit, setHLiqViscosityUnit] = useState();
  const [lLiq_GasParticleDia, setLLiq_GasParticleDia] = useState();
  const [lLiq_GasParticleDiaUnit, setLLiq_GasParticleDiaUnit] = useState();
  const [hLiqFlowrate, setHLiqFlowrate] = useState();
  const [hLiqFlowrateUnit, setHLiqFlowrateUnit] = useState();
  const [overDesignFactor, setOverDesignFactor] = useState();
  const [universal, setUniversal] = useState(false);
  const [gas, setGas] = useState();
  const [lliq, setLliq] = useState();
  const [hliq, setHliq] = useState();
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState();
  const apiClient = useApiClient();
  const pRef = useRef();

  const propsFile = {
    name: 'file',
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        //
      }
      if (info.file.status === 'done') {
        openNotification(
          'Success',
          `${info.file.name} file uploaded successfully`,
          200
        );
      } else if (info.file.status === 'error') {
        openNotification('Error', `${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(() => {
    if (active == 'process') {
      const offset = 150;
      const elementPosition = pRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [active]);
  const initilize = () => {
    processConditionApi.load(apiClient, sizing.id).then((res) => {
      if (!res) return;
      setRes(res);
      setSitAtmPress(res.Site_Atm_Press);
      setSitAtmPressUnit(res.Site_Atm_Press_unit);

      setActLLiqDensity(res.Act_L_Liq_Density);
      setActLLiqDensityUnit(res.Act_L_Liq_Density_unit);

      setPressure(res.Pressure);
      setPressureUnit(res.Pressure_unit);

      setLLiqViscosity(res.L_Liq_Viscosity);
      setLLiqViscosityUnit(res.L_Liq_Viscosity_unit);

      setTemperature(res.Temperature);
      setTemperatureUnit(res.Temperature_unit);

      setLLiqFlowrate(res.L_Liq_Flowrate);
      setLLiqFlowrateUnit(res.L_Liq_Flowrate_unit);

      setActGasDensity(res.Act_Gas_Density);
      setActGasDensityUnit(res.Act_Gas_Density_unit);

      setLLiq_HLiqParticleDia(res.L_Liq_H_Liq_Particle_Dia);
      setLLiq_HLiqParticleDiaUnit(res.L_Liq_H_Liq_Particle_Dia_unit);

      setGasViscosity(res.Gas_Viscosity);
      setGasViscosityUnit(res.Gas_Viscosity_unit);

      setActHLiqDensity(res.Act_H_Liq_Density);
      setActHLiqDensityUnit(res.Act_H_Liq_Density_unit);

      setGasFlowrate(res.Gas_Flowrate);
      setGasFlowrateUnit(res.Gas_Flowrate_unit);

      setHLiqViscosity(res.H_Liq_Viscosity);
      setHLiqViscosityUnit(res.H_Liq_Viscosity_unit);

      setLLiq_GasParticleDia(res.L_Liq_Gas_Particle_Dia);
      setLLiq_GasParticleDiaUnit(res.L_Liq_Gas_Particle_Dia_unit);

      setHLiqFlowrate(res.H_Liq_Flowrate);
      setHLiqFlowrateUnit(res.H_Liq_Flowrate_unit);

      setOverDesignFactor(res.Over_Design_Factor);

      setUniversal(res.universal);

      setGas(res.gas);

      setLliq(res.l_liq);
      setHliq(res.h_liq);
    });
  };
  useEffect(() => {
    if (sizing) initilize();
  }, [sizing]);
  const onChange = (e) => {
    setUniversal(e.target.checked);
    if (e.target.checked === true) {
      setGas(overDesignFactor);
      setLliq(overDesignFactor);
      setHliq(overDesignFactor);
    } else setOverDesignFactor(null);
  };
  const goNextStep = () => {
    if (active !== 'general') setActive('general');
  };
  const goBackStep = () => {
     setActive('design');
  };
  const submit = () => {
    const data = {
      Site_Atm_Press: sitAtmPress,
      Site_Atm_Press_unit: sitAtmPressUnit,
      Pressure: pressure,
      Pressure_unit: pressureUnit,
      Temperature: temperature,
      Temperature_unit: temperatureUnit,
      Act_Gas_Density: actGasDensity,
      Act_Gas_Density_unit: actGasDensityUnit,
      Gas_Viscosity: gasViscosity,
      Gas_Viscosity_unit: gasViscosityUnit,
      Gas_Flowrate: gasFlowrate,
      Gas_Flowrate_unit: gasFlowrateUnit,
      L_Liq_Gas_Particle_Dia: lLiq_GasParticleDia,
      L_Liq_Gas_Particle_Dia_unit: lLiq_GasParticleDiaUnit,
      Act_L_Liq_Density: actLLiqDensity,
      Act_L_Liq_Density_unit: actLLiqDensityUnit,
      L_Liq_Viscosity: lLiqViscosity,
      L_Liq_Viscosity_unit: lLiqViscosityUnit,
      L_Liq_Flowrate: lLiqFlowrate,
      L_Liq_Flowrate_unit: lLiqFlowrateUnit,
      L_Liq_H_Liq_Particle_Dia: lLiq_HLiqParticleDia,
      L_Liq_H_Liq_Particle_Dia_unit: lLiq_HLiqParticleDiaUnit,
      Act_H_Liq_Density: actHLiqDensity,
      Act_H_Liq_Density_unit: actHLiqDensityUnit,
      H_Liq_Viscosity: hLiqViscosity,
      H_Liq_Viscosity_unit: hLiqViscosityUnit,
      H_Liq_Flowrate: hLiqFlowrate,
      H_Liq_Flowrate_unit: hLiqFlowrateUnit,
      Over_Design_Factor: overDesignFactor,
      gas: gas,
      universal: universal,
      l_liq: lliq,
      h_liq: hliq,
    };

    setLoading(true);

    processConditionApi
      .save(apiClient, sizing?.id, data)
      .then((res) => {
        openNotification(
          'Success',
          'Proccess Conditions saved.',
          200
        );
        goNextStep();
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };
  return (
    <Card style={{}} type="inner" title="Process conditions">
      <div ref={pRef}>
        <Form onFinish={submit} className="p-3">
          <div className="mb-5 mt-5 ">
            <Typography.Title level={4} className="title-1 mt-5">
              Process Conditions
            </Typography.Title>
          </div>
          <div className="grid  self-center justify-center">
            <Typography.Text>
              You can enter the input values for this section using the Excel
              template. Simply download the template, fill it out, and upload
              it.
            </Typography.Text>
            <a className="mt-5 !text-[#2F54EB] mb-3">Download Template</a>
            <div>
              <Upload {...propsFile}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>
            <div className="grid grid-cols-4 text-start items-center   justify-evenly mb-5 mt-5 ">
              <Typography.Text className="">Site Atm. Press.</Typography.Text>
              <div className="">
                <Input
                  required
                  onChange={(e) => setSitAtmPress(e.target.value)}
                  value={sitAtmPress}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={(v) => setSitAtmPressUnit(v)}
                      type={'Pressure'}
                      defaultUnitValue={
                        res?.site__atm__press_unit?.unit_name ??
                        sizing?.unit_system == 'si'
                          ? 'Bar'
                          : 'Barg'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
              <div className=""></div>
              <div className=""></div>
            </div>
            <div className="grid grid-cols-4 text-start items-center   mt-5 ">
              <Typography.Text className="">Proccess Condition</Typography.Text>
              <div className="grid">
                <Typography.Text>Pressure</Typography.Text>
                <Input
                  required
                  onChange={(e) => setPressure(e.target.value)}
                  value={pressure}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setPressureUnit}
                      type={'Pressure'}
                      defaultUnitValue={res?.pressure_unit?.unit_name ?? 'Barg'}
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>

              <div className="grid">
                <Typography.Text>Temperature</Typography.Text>
                <Input
                  required
                  onChange={(e) => setTemperature(e.target.value)}
                  value={temperature}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setTemperatureUnit}
                      type={'Temperature'}
                      defaultUnitValue={res?.temperature_unit?.unit_name ?? 'C'}
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
            </div>
            <div className="grid grid-cols-4  items-end  mt-8 ">
              <div></div>
              <Typography.Title level={5} className=" text-start">Gas</Typography.Title>
              <Typography.Title level={5} className=" text-start ">
                Light Liquid
              </Typography.Title>
              <Typography.Title level={5}  className="  text-start">
                Heavy Liquid <sup>1</sup>
              </Typography.Title>
            </div>
            <div className="grid grid-cols-4  items-center  mt-1 ">
              <Typography.Text>Actual Density</Typography.Text>
              <div className="grid">
                <Input
                  required
                  onChange={(e) => setActGasDensity(e.target.value)}
                  value={actGasDensity}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setActGasDensityUnit}
                      type={'Density'}
                      defaultUnitValue={
                        res?.act__gas__density_unit?.unit_name ?? 'kg/m3'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
              <div className="grid">
                <Input
                  required
                  onChange={(e) => setActLLiqDensity(e.target.value)}
                  value={actLLiqDensity}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setActLLiqDensityUnit}
                      type={'Density'}
                      defaultUnitValue={
                        res?.act__l__liq__density_unit?.unit_name ?? 'kg/m3'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
              <div className="grid">
                <Input
                  required
                  onChange={(e) => setActHLiqDensity(e.target.value)}
                  value={actHLiqDensity}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setActHLiqDensityUnit}
                      type={'Density'}
                      defaultUnitValue={
                        res?.act__h__liq__density_unit?.unit_name ?? 'kg/m3'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
            </div>
            <Divider />
            <div className="grid grid-cols-4  items-center  justify-between  ">
              <Typography.Text>Viscosity</Typography.Text>
              <div className="">
                <Input
                  required
                  onChange={(e) => setGasViscosity(e.target.value)}
                  value={gasViscosity}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setGasViscosityUnit}
                      type={'Viscosity'}
                      defaultUnitValue={
                        res?.gas__viscosity_unit?.unit_name ?? 'cP'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
              <div className="">
                <Input
                  required
                  onChange={(e) => setLLiqViscosity(e.target.value)}
                  value={lLiqViscosity}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setLLiqViscosityUnit}
                      type={'Viscosity'}
                      defaultUnitValue={
                        res?.l__liq__viscosity_unit?.unit_name ?? 'cP'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
              <div>
                <Input
                  required
                  onChange={(e) => setHLiqViscosity(e.target.value)}
                  value={hLiqViscosity}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setHLiqViscosityUnit}
                      type={'Viscosity'}
                      defaultUnitValue={
                        res?.h__liq__viscosity_unit?.unit_name ?? 'cP'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
            </div>
            <Divider />
            <div className="grid grid-cols-4  items-center  justify-between  ">
              <Typography.Text className="">Flowrate</Typography.Text>
              <div className="">
                <Input
                  required
                  onChange={(e) => setGasFlowrate(e.target.value)}
                  value={gasFlowrate}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setGasFlowrateUnit}
                      type={'Mass Flowrate'}
                      defaultUnitValue={
                        res?.gas__flowrate_unit?.unit_name ?? 'kg/h'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
              <div>
                <Input
                  required
                  onChange={(e) => setLLiqFlowrate(e.target.value)}
                  value={lLiqFlowrate}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setLLiqFlowrateUnit}
                      type={'Mass Flowrate'}
                      defaultUnitValue={
                        res?.l__liq__flowrate_unit?.unit_name ?? 'kg/h'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
              <div>
                <Input
                  required
                  onChange={(e) => setHLiqFlowrate(e.target.value)}
                  value={hLiqFlowrate}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setHLiqFlowrateUnit}
                      type={'Mass Flowrate'}
                      defaultUnitValue={
                        res?.h__liq__viscosity_unit?.unit_name ?? 'kg/h'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
            </div>
            <Divider />
            <div className="grid grid-cols-4  items-center  justify-between">
              <Typography.Text className="">Over Design Factor:</Typography.Text>
              <Checkbox checked={universal} onChange={onChange}>
                Universal
              </Checkbox>
              <Input
                required
                disabled={!universal}
                className="input-general w-36"
                value={overDesignFactor}
                onChange={(e) => {
                  setOverDesignFactor(e.target.value);
                  setGas(e.target.value);
                  setLliq(e.target.value);
                  setHliq(e.target.value);
                }}
              />

              <div></div>
            </div>
            <div className="grid grid-cols-4 mt-8 items-center   justify-between">
              <div></div>
              <Input
                required
                className="input-addon-general2 w-40 "
                type="number"
                value={gas}
                onChange={(e) => setGas(e.target.value)}
                addonBefore="Gas"
                defaultValue=""
                disabled={universal}
              />
              <Input
                required
                value={lliq}
                onChange={(e) => setLliq(e.target.value)}
                className="input-addon-general2 w-40 "
                addonBefore="L. liq"
                type="number"
                defaultValue=""
                disabled={universal}
              />
              <Input
                required
                value={hliq}
                disabled={universal}
                onChange={(e) => setHliq(e.target.value)}
                className="input-addon-general2 w-40"
                addonBefore="H. liq"
                type="number"
                defaultValue=""
              />
            </div>

            <Divider />
            <div className="grid grid-cols-3  items-center   justify-between">
              <Typography.Text className="">Particle Diameter</Typography.Text>
              <div className="grid">
                <Typography.Text className="">
                  Gas / Light Liquid
                  <Tooltip title={tip_L_liq_Gas_Particle_Dia}>
                    <InfoCircleOutlined
                      onMouseOver={() =>
                        setTipTableNumber({
                          id: 5,
                          params: { k29: lLiqViscosity },
                        })
                      }
                      role="button"
                      className="ms-1"
                      style={{ color: '#808080' }}
                    />
                  </Tooltip>
                </Typography.Text>
                <Input
                  required
                  onChange={(e) => setLLiq_GasParticleDia(e.target.value)}
                  value={lLiq_GasParticleDia}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setLLiq_GasParticleDiaUnit}
                      type={'Length'}
                      defaultUnitValue={
                        res?.l__liq__gas__particle__dia_unit?.unit_name ?? 'µm'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>

              <div className="grid">
                <Typography.Text>
                  Light / Heavy Liquid
                  <Tooltip title={tip_L_liq_Gas_Particle_Dia}>
                    <InfoCircleOutlined
                      onMouseOver={() =>
                        setTipTableNumber({
                          id: 5,
                          params: { k29: lLiqViscosity },
                        })
                      }
                      role="button"
                      className="ms-1"
                      style={{ color: '#808080' }}
                    />
                  </Tooltip>
                </Typography.Text>
                <Input
                  required
                  onChange={(e) => setLLiq_HLiqParticleDia(e.target.value)}
                  value={lLiq_HLiqParticleDia}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setLLiq_HLiqParticleDiaUnit}
                      type={'Length'}
                      defaultUnitValue={
                        res?.l__liq__h__liq__particle__dia_unit?.unit_name ??
                        'µm'
                      }
                    />
                  }
                  type="number"
                  style={{ width: 130 }}
                  className="input-addon-general focus:border-none focus:outline-none !min-w-[150px]"
                />
              </div>
            </div>
            <Divider />
            <sub>
            1-Not needed for two-phase separators.
            </sub>
            <div className="flex justify-between items-center ">
            <Button
               onClick={()=> goBackStep()}
               icon={<ArrowLeftOutlined/>}
                className="w-20    mb-5 mt-4 text-gray-800 bg-white-800 hover:bg-gray-900  font-medium  text-sm  py-5 me-2 "
              >
                 Back
              </Button>
              <Button
                htmlType="submit"
                disabled={loading}
                loading={loading}
                className=" w-36   mb-5 mt-5  text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm  py-5 me-2 "
              >
                Save 
              </Button>
              <Button
              iconPosition='end'
               onClick={()=> goNextStep()}
               icon={<ArrowRightOutlined/>}
                className="w-20    mb-5 mt-4 text-gray-800 bg-white-800 hover:bg-gray-900  font-medium  text-sm  py-5 me-2 "
              >
                 Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default ProcessConditions;
