import React, { useEffect, useState } from "react";
import { makeChart } from "../helpers/makeData";
import { Column } from "@ant-design/plots";
import { weightApi } from "../API/weightApi";
import useApiClient from "../Settings/useApiClient";

const CaseChart = ({sizing_id}) => {
  const [data, setData] = useState();
  const apiClient = useApiClient();

  const loadData=()=>{
    if(sizing_id)
     weightApi
          .load(apiClient, sizing_id)
          .then((r) => {
            load(r.weights.datasource)
          
          })
         
     
  }
  const load =async (dataSource) => {

    makeChart(dataSource).then((res) => {
      const config = {
        data: res,
        xField: "No",
        yField: "Weight",
        xAxis: false,
        legend:false,
        height: 350,
        colorField: "name",
        color: ({ no }) => "steelblue",
        style: {
          inset: 2,
        },
        label: {
          position: "top", // موقعیت اولیه
          style: {
            fill: "#fff",
            fontSize: 11,
          },
          offsetY: 30,
          formatter: (datum) => datum, // مقادیر سفارشی
        },

        grid: {
          line: {
            style: {
              stroke: "#000000", // Customize grid line color
              lineWidth: 2, // Customize grid line width
            },
          },
        },
 
      };
      setData(config);
    });
  };
  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("case_study", loadData);
    }
    loadData();
    return () => {
      window.removeEventListener("case_study", loadData);
    };
  }, []);

  return (
    <div className="overflow-y-hidden relative overflow-x-auto m-auto text-center grid place-items-center ps-3 ">
      {data && <div className="relative">
        <Column className="" {...data} />
       <strong className="text-xs absolute -left-10 aspect-auto top-40  -rotate-90">Weight (kg)</strong>
        
        </div>}
      <strong className="text-xs">Case No.</strong>
    </div>
  );
};

export default CaseChart;
