import React from 'react'
import {  fetchSchema, fetchSchemaData } from '../Tools/tools'
import { toFixed } from '../helpers/Converts'
import { I106f, I111f, O106f, O111f } from '../helpers/reportHelp'
import Header from './reports/Header'
import Volumes from './reports/Volumes'

function ReportRoller({I40,res}) {
  const schema=()=>{
 
    return fetchSchema(I40,res.configuration?.Demister,res.configuration?.Diverter,res.configuration?.Coalescer,"configs")

    // return fetchSchema(5,1,1,1,"configs")

  }
  return (
    <table className='report'
    style={{
      borderCollapse: 'collapse',
      width: '90%',
      margin: 'auto',
      overflow: 'hidden',
    }}
  >
     <Header res={res} sheet={2} />
    <tbody>
      <tr className="p-5 " style={{ height: 50 }}>
        <td colSpan={5}> </td>
      </tr>
      <tr>
        <td colSpan={5} className="!text-[11px]">
          <strong> Drawing</strong>
        </td>
      </tr>

      <tr className="">
        <td colSpan={5} className="relative  w-full h-[400px] ">
          {/* <div className="absolute top-0 aspect-[16/9]   h-[700px] w-[500px] !-right-14"> */}
          <div
          className={`absolute top-0 aspect-[16/9]   ${
    
               [1, 2, 7].find((x) => x === I40)
              ? '!w-full grid !h-[645px] justify-end items-end  right-0 '
              : '!w-full '
          } m-auto`}
        >
          <img src={`${schema()}`} className={`m-auto ${ [1, 2, 7].find((x) => x === I40) ?"h-[660px]  w-[350px]":""}`} />
          {fetchSchemaData(res.data,I40,res.configuration?.Demister,res.configuration?.Diverter,res.configuration?.Coalescer)}
          {/* {fetchSchemaData(res.data,5,1,1,1)} */}
        </div>
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
        <Volumes res={res} I40={I40} />
        </td>
        <td colSpan={3} className="w-full"></td>
      </tr>
      <tr>
        <td className="h-[10px]"></td>
      </tr>
      <tr>
        <td colSpan={2} className="!text-[11px] !font-bold">
          Notes
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="text-wrap h-40">
          
          <br />
          {res.sizing.note}
          
          <br />
        </td>
      </tr>
    </tbody>
  </table>
  )
}

export default ReportRoller