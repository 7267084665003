import { UploadOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Select, Typography, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useNotify from "../hooks/useNotify";
import useApiClient from "../Settings/useApiClient";
import { ProjectApi } from "../API/ProjectApi";
import { ParsError } from "../Tools/ParsError";

const ProjectForm =  forwardRef(
  ({  onFinish }, ref) => {
  const [form] = Form.useForm();
  const [logo1, setLogo1] = useState(null);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [defaultFileList2, setDefaultFileList2] = useState([]);
  const [logo2, setLogo2] = useState(null);
  const { openNotification } = useNotify();
  const apiClient = useApiClient();

  const [project, setProject] = useState({
    id: "",
    name: "",
    initials: "",
    description: "",
    project_number: "",
    unit_system: "si",
    client: "",
  });
  const [projectError, setProjectError] = useState({
    id: "",
    name: "",
    initials: "",
    description: "",
    unit_system: "",
    logo1: "",
    logo2: "",
  });

  const showErrors = (error) => {
    setProjectError({
      name: error?.name?.[0] ?? "",
      initials: error?.initials?.[0] ?? "",
      description: error?.description?.[0] ?? "",
      unit_system: error?.unit_system?.[0] ?? "",
    });
  };
  
  const props = {
    beforeUpload: (file) => {
      const isPic =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isPic) {
        openNotification("Error", `${file.name} is not a picture file`);
        return false;
      }
      setLogo1(file);
      return false;
    },
    defaultFileList: defaultFileList,
  };
  const props2 = {
    beforeUpload: (file) => {
      const isPic =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isPic) {
        openNotification("Error", `${file.name} is not a picture file`);
        return false;
      }
      setLogo2(file);
      return false;
    },
    defaultFileList: defaultFileList2,
  };
  const submit = () => {
    const data = {};
    const form = new FormData();
    form.append('name', project.name);
    form.append('initials', project.initials);
    form.append('description', project.description);
    form.append('client ', project.client);
    form.append('project_number', project.project_number);
    form.append('logo1', logo1);
    form.append('logo2', logo2);
    form.append('unit_system', project.unit_system);
    ProjectApi.new(apiClient, form)
      .then((res) => {
        // setProject({ ...project, id: res.project_id });
       
        // openNotification('Success', 'Project created successfully.', 200);
        onFinish(res.project_id)
      })
      .catch((e) => {
        if (e?.response?.status === 422) {
          openNotification('Validation Error', ParsError(e?.response),"error");
          if (e?.response?.data?.message) {
            showErrors(e?.response?.data.errors);
          }
        }
      })
      .finally(() => {});
  };
   useImperativeHandle(ref, () => ({
        submitForm() {
          form.submit();
        },
      }));
  return (
    <Form form={form}  onFinish={submit}>
      <details className="mb-4 " open>
        <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
          Basic information
        </summary>

        <div className="grid grid-flow-col grid-cols-3  gap-6 p-3">
          <div>
            <Typography.Text className="important">
              Project name
            </Typography.Text>
            <Input
              required
              value={project?.name}
              onChange={(e) => setProject({ ...project, name: e.target.value })}
              className="input-general"
              placeholder="Type..."
            />
            <Typography.Text type="danger">
              {projectError?.name}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="important">
              Project initials
            </Typography.Text>
            <Input
              value={project?.initials}
              onChange={(e) =>
                setProject({ ...project, initials: e.target.value })
              }
              required
              className="input-general"
              placeholder="Type..."
            />
            <Typography.Text type="danger">
              {projectError?.initials}
            </Typography.Text>
          </div>
          <div className="grid">
            <Typography.Text className="important">
              Unit systems
            </Typography.Text>
            <Select
              required
              defaultValue={project.unit_system}
              onChange={(v) => setProject({ ...project, unit_system: v })}
              // onChange={setUnitSystem}
              options={[
                { label: "SI (kg, m, mm, Bar, °C)", value: "si" },
                {
                  label: "Field (lb, ft, in, psi, °F)",
                  value: "field",
                },
              ]}
            />
            <Typography.Text type="danger">
              {projectError?.unit_system}
            </Typography.Text>
          </div>
        </div>
        <div className="grid grid-flow-col grid-cols-3  gap-6 p-3">
          <div>
            <Typography.Text>Project Number</Typography.Text>
            <Input
              value={project?.project_number}
              onChange={(e) =>
                setProject({
                  ...project,
                  project_number: e.target.value,
                })
              }
              className="input-general"
              placeholder="Type..."
            />
            <Typography.Text type="danger">
              {projectError?.project_number}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>Employer </Typography.Text>
            <Input
              value={project?.client}
              onChange={(e) =>
                setProject({
                  ...project,
                  client: e.target.value,
                })
              }
              className="input-general"
              placeholder="Type..."
            />
            <Typography.Text type="danger">
              {projectError?.client}
            </Typography.Text>
          </div>
        </div>
        <div className="grid grid-flow-col grid-cols-1   p-3">
          <div className="col-span-3">
            <Typography.Text>Description</Typography.Text>
            <TextArea
              count={{ max: 300, show: true }}
              placeholder="Type..."
              rows={4}
              onChange={(e) =>
                setProject({
                  ...project,
                  description: e.target.value ?? "",
                })
              }
              value={project?.description ?? ""}
              className=""
            ></TextArea>
          </div>
        </div>
        <div>
          <Flex className="gap-2 items-start font-bold">
            <img src="/images/urgent2.svg" /> If you want logos to be included
            in the report, you can upload them here.
          </Flex>
          <p className="text-[grey] ps-8 ">
            Recommanded resolution is 150*150 with file size
          </p>
          <label className="text-sm block mt-4 font-bold">Employer logo</label>
          <Upload {...props} accept=".png,.jpg,.jpeg" maxCount={1}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          <label className="text-sm block mt-4 font-bold">
            Consultant engineering logo
          </label>
          <Upload {...props2} accept=".png,.jpg,.jpeg" maxCount={1}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
        
      </details>
    </Form>
  );
});

export default ProjectForm;
