import React from "react";

import { AF19f, AL39f, K32, K32f, K33f, K34f } from "../helpers/reportHelp";
import { toFixed } from "../helpers/Converts";
import Header from "./reports/Header";
import ProcessData from "./reports/ProcessData";
import SizingAssumptions from "./reports/SizingAssumptions";
import MaterialDesign from "./reports/MaterialDesign";
import DimensionsGeneral from "./reports/DimensionsGeneral";
import DimensionsCompartments from "./reports/DimensionsCompartments";
import SeparatorConfigurations from "./reports/SeparatorConfigurations";

const ReportTables = ({ I40, res }) => {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "90%",
        placeSelf:"center",
        margin: "0 20px",
      }}
    >
      <Header res={res} sheet={1} />
      <tbody>
        <tr className="p-5 " style={{ height: 1 }}>
          <td colSpan={5}> </td>
        </tr>
        <tr>
          <td colSpan={2} className="!text-[11px]">
            <strong> Process Data</strong>
          </td>
          <td></td>
          <td colSpan={2} className="!text-[11px]">
            <strong>Sizing Assumptions</strong>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <ProcessData res={res} I40={I40} />
            <MaterialDesign res={res}/>
          </td>
          <td style={{ width: 50 }}> </td>
          <td colSpan={2}>
            <SizingAssumptions  res={res} I40={I40}/>
          </td>
        </tr>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        <tr style={{ height: 1 }}>
          <td colSpan={5}></td>
        </tr>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        <tr>
          <td colSpan={2}>
            <strong>Dimensions - General </strong>
          </td>
          <td style={{ width: 50 }}></td>
          <td colSpan={2}>
            <strong>Dimensions - Compartments </strong>{" "}
          </td>
        </tr>

        <tr>
          <td colSpan={2}>
           <DimensionsGeneral res={res} />
          </td>
          <td></td>
          <td colSpan={2}>
           <DimensionsCompartments res={res} I40={I40} />
          </td>
        </tr>
        {/* /////////////////////////////////////////// */}
        <tr style={{ height: 1 }}>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td colSpan={2} className="!text-[11px] !font-bold">
            Separator Configurations
          </td>
          <td colSpan={3}></td>
        </tr>
        {/* ////////////////////////////// */}
        <tr>
          <td colSpan={5}>
            <SeparatorConfigurations  res={res} I40={I40} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReportTables;
