import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem('token'); // بررسی وضعیت ورود به سیستم

    return !isAuthenticated ? 
    <div className='max-full'>
    {children}
    </div>
     : <Navigate to="/" />;
};

export default PublicRoute;
