
export const makeChart =async (dataSource) => {
       
  if (dataSource) dataSource = JSON.parse(dataSource);
  else dataSource=[]
  let d = [];
  dataSource?.map((data) =>
    d.push({ No: data.id, Weight: data.Separator_weight_kg, name: 'Weight' })
  );
  return d;
};
export const makeTable =async (dataSource) => {
  if (dataSource) dataSource = JSON.parse(dataSource);
  else dataSource=[]
  const columns = [
    {
      title: 'No.',
      dataIndex: 'id',
      key: 'id',
      width: 30,
      
    },
    {
      title: 'Nominal L/D',
      dataIndex: 'Nominal',
      key: 'Nominal',
      width: 45,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Exact L/D',
      dataIndex: 'Exact',
      key: 'Exact',
      width: 35,
    },
    {
      title: 'D (m)',
      dataIndex: 'D',
      key: 'D',
      width: 30,
    },
    {
      title: 'L (m)',
      dataIndex: 'L',
      key: 'L',
      width: 30,
    },
    // {
    //   title: 'Separator Type',
    //   dataIndex: 'Separator',
    //   key: 'Separator',
    //   width: 150,
    // },
    // {
    //   title: 'Dish Type',
    //   dataIndex: 'Dish',
    //   key: 'Dish',
    //   width: 100,
    // },
    {
      title: 'Shell',
      key: 'Shell',
      children: [
        {
          title: 'Shell Thickness (in./mm) ',
          dataIndex: 'Shell_Thickness',
          key: 'Shell_Thickness',
          width: 70,
        },
        {
          title: <>Surface Area  (m<sup>2</sup>)</>,
          dataIndex: 'Shell_Surface',
          key: 'Shell_Surface',
          width: 70,
        },
      ],
    },
    {
      title: 'Dishes',
      key: 'Dishes',
      children: [
        {
          title: 'Dishes Thickness (in./mm)',
          dataIndex: 'Dishes_Thickness',
          key: 'Dishes_Thickness',
          width: 70,
        },
        {
          title: <>Surface Area (m<sup>2</sup>)</>,
          dataIndex: 'Dishes_Surface',
          key: 'Dishes_Surface',
          width: 70,
        },
      ],
    },
    {
      title: 'Separator Weight (kg / lb)',
      dataIndex: 'Separator_weight',
      key: 'Separator_weight',
      width: 80,
      sorter: (a, b) =>  ('' + a.Separator_weight).localeCompare(b.Separator_weight),
      style:{textAlign:"center"}
    },
  ];
  return { dataSource: dataSource, columns: columns };
  // return dataSource
};
export const makeTable2 =async (dataSource) => {
  if (dataSource) dataSource = JSON.parse(dataSource);
  else dataSource=[]
  const columns = [
    {
      title: 'No.',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    
    {
      title: 'Exact L/D',
      dataIndex: 'Exact',
      key: 'Exact',
      width: 80,
    },
    {
      title: 'D',
      dataIndex: 'D',
      key: 'D',
      width: 70,
    },
    {
      title: 'L',
      dataIndex: 'L',
      key: 'L',
      width: 70,
    },
    // {
    //   title: 'Separator Type',
    //   dataIndex: 'Separator',
    //   key: 'Separator',
    //   width: 150,
    // },
    // {
    //   title: 'Dish Type',
    //   dataIndex: 'Dish',
    //   key: 'Dish',
    //   width: 100,
    // },
    {
      title: 'Shell',
      key: 'Shell',
      children: [
        {
          title: 'Shell Thickness (in./mm) ',
          dataIndex: 'Shell_Thickness',
          key: 'Shell_Thickness',
          width: 70,
        },
        {
          title: <>Surface Area  (m<sup>2</sup>)</>,
          dataIndex: 'Shell_Surface',
          key: 'Shell_Surface',
          width: 70,
        },
      ],
    },
    {
      title: 'Dishes',
      key: 'Dishes',
      children: [
        {
          title: 'Dishes Thickness (in./mm)',
          dataIndex: 'Dishes_Thickness',
          key: 'Dishes_Thickness',
          width: 70,
        },
        {
          title: <>Surface Area (m<sup>2</sup>)</>,
          dataIndex: 'Dishes_Surface',
          key: 'Dishes_Surface',
          width: 70,
        },
      ],
    },
    {
      title: 'Separator Weight (kg / lb)',
      dataIndex: 'Separator_weight',
      key: 'Separator_weight',
    },
  ];
  return { dataSource: dataSource, columns: columns };
  // return dataSource
};