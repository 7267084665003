import { Button, Spin } from "antd";
import Typography from "antd/es/typography/Typography";
import React, { useContext, useEffect, useRef } from "react";
import { ProjectContext } from "../Context/ProjectContext";
import useApiClient from "../Settings/useApiClient";
import { preAnalyze } from "../helpers/Analyze";
import { sizingApi } from "../API/sizingApi";
import { useParams } from "react-router-dom";
import useNotify from "../hooks/useNotify";
import { event_change_data, event_set_caseStudy } from "../services/events";
import { ParsError } from "../Tools/ParsError";
import { weightApi } from "../API/weightApi";
import { useState } from "react";

const AnalyzeLoadingPage = () => {
  const [l_or_D, setL_or_D] = useState(0);

  const {
    sizing,
    setAnalyzeLoading,
    analyzeLoading,
    setSizing,
    setCaseStudyShow,
  } = useContext(ProjectContext);
  const workerRef = useRef(null);
  const { openNotification } = useNotify();
  const { id } = useParams();
  const apiClient = useApiClient();

  const saveDataSource = async () => {
    const data = { datasource: await localStorage.getItem("dataSource") };

    await sizingApi
      .save_datasource(apiClient, id, data)
      .then((r) => {
        setSizing({ ...sizing, status: 2 });
        setCaseStudyShow(true);
      })
      .finally(() => setAnalyzeLoading(2));
  };

  const preParingAnalyzeIt = () => {
    weightApi
      .analyze(apiClient, id)
      .then((res) => {
        preAnalyze(res).then(() => {
          workerRef.current = new Worker(
            new URL("/js/analyze.js", window.location.href)
          );
          workerRef.current.onmessage = async (e) => {
            const { action, key, value } = e.data;

            if (action === "change_data") {
              event_change_data(key);
            } else if (action === "save_server") {
              // await saveData(key, value);
            } else if (action === "case_study") {
              event_set_caseStudy();
            } else if (action === "setItem") {
              localStorage.setItem(key, value);
            } else if (action === "getItem") {
              const value = localStorage.getItem(key);
              workerRef.current.postMessage({
                action: "receivedData",
                key,
                value,
              });
            } else if (action === "reject") {
              workerRef.current.terminate();
              setAnalyzeLoading(-1);
            } else if (action === "finish") {
              saveDataSource();
              if (workerRef.current) {
                workerRef.current.terminate();
                setAnalyzeLoading(2);
              }
            } else if (action == "msg") {
              openNotification("Alert", key, 200,"analyze");
            }
          };
        });
      })
      .catch((e) => {
        if (e?.response?.status === 422) {
          openNotification("Error", ParsError(e?.response), "Error");
          setAnalyzeLoading(-1);
        }
      })
      .finally(() => {});
  };

  const handleCancel = () => {
    setAnalyzeLoading(-1);
    if (workerRef.current) {
      workerRef.current.terminate();
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setL_or_D(Number(localStorage.getItem("Q40")));
    }, 200);
    if (analyzeLoading == 1) preParingAnalyzeIt();
    return function cleanup() {
      clearInterval(interval);
    };
  }, [analyzeLoading]);
  return (
    <>
      {analyzeLoading == 1 && (
        <div className="fixed z-50 w-full h-full  translate-x-3 grid content-center justify-center items-center bg-black opacity-70">
          <Typography.Title
            level={1}
            className="!text-white text-center m-auto"
          >
            Weight Analysis...
          </Typography.Title>
          <p className="!text-white block text-center ">
            Analysis in progress. Please wait ...
          </p>
          <div style={{ textAlign: "center", padding: 24 }}>
            <Spin size="large" />
            <span className="text-white ms-6">L/D: {l_or_D}</span>
          </div>
          ‌
          <Button
            onClick={handleCancel}
            icon={<img src="/images/cancel.svg" />}
            className="!text-white hover:!text-black w-24 m-auto"
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default AnalyzeLoadingPage;
