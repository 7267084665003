import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case4_8({info}) {
    return (
      <>
          <div className="HHLL-H-4 grid gap-x-2 text-start leading-4">
         <div className='flex items-center justify-between gap-1'><span>HHLL</span><span>{toFixed(info.AN71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>HLL</span><span>{toFixed(info.AK71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>NLL</span><span>{toFixed(info.AH71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>LLL</span><span>{toFixed(info.AA71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>LLLL</span><span>{toFixed(info.X71,0)}</span></div>
             </div>
             <div className="HHLIL-H-4  grid text-start leading-4">
               <div className='flex items-center justify-between gap-1 text-start'><span>HHLIL</span><span>{toFixed(info.T71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>HLIL</span><span>{toFixed(info.Q71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>NLIL</span><span>{toFixed(info.N71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>LLIL</span><span>{toFixed(info.G71,0)}</span></div>
               <div className='flex items-center justify-between gap-1'><span>LLLIL</span><span>{toFixed(info.D71,0)}</span></div>
      </div>
      <div className="L-H-4">
        <label>L </label>
        {toFixed(info.BH75, 3)}
      </div>
      <div className="D-H-4">
        <label>D </label>
        {toFixed(info.BD75, 3)}
      </div>

      <div className="hv-H-4 text-center">
        <label>
          H<sub className="align-sub ">v</sub>
        </label>
        <br />
        {toFixed(info.AR71, 0)}
      </div>
      <div className="Hboot-H-4">
        <label>
          H <sub className="align-sub ">boot</sub>
        </label>
        {toFixed(info.AV75, 0)}
      </div>
      <div className="Dboot-H-4">
        <label>
          D <sub className="align-sub ">boot</sub>
        </label>
        {toFixed(info.AR75, 3)}
      </div>
        </>)
}

export default Case4_8