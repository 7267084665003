import React, { useEffect, useState } from 'react';
import {  makeTable } from '../helpers/makeData';
import { Table } from 'antd';
import useApiClient from '../Settings/useApiClient';
import { weightApi } from '../API/weightApi';

const CaseStudy = ({sizing_id,maximize}) => {
  const [columns, setColumns] = useState([]);
  const apiClient = useApiClient();

  const [dataSource, setDataSource] = useState([]);
    const loadData = () => {
      if(sizing_id)
      weightApi.load(apiClient, sizing_id).then((r) => {
        load(r.weights.datasource);
      });
    };
  const load=(datasource)=>{
    makeTable(datasource).then(res=>{
      setColumns(res.columns);
      setDataSource(res.dataSource);
     });

  }
  useEffect(() => {
    if (window.addEventListener) {
      //constant variablels

      window.addEventListener('case_study', loadData);
    }
    loadData()
    return ()=>{window.removeEventListener('case_study',loadData)}
  }, []);
  return (
    <Table
      size={'small'}
      pagination={false}
      scroll={{
        y: 55 * 9,
        x:1250
      }}
      dataSource={dataSource}
      columns={columns}
    />
  );
};

export default CaseStudy;
