import React, { createContext } from 'react';
import { notification } from 'antd';
import { CheckCircleTwoTone, WarningOutlined } from '@ant-design/icons';
import { yellow,red } from '@ant-design/colors';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message, description,type='error',model="normal") => {
    
    if(model=="normal")
    api.info({
      message: message,
      description: description,
      placement: 'topRight',  
      showProgress: true,
      pauseOnHover: true,
      icon: type=='error'|'Error'?<WarningOutlined style={{ color: red[6] }} />:
      type=='warning'|'Warning'?<WarningOutlined style={{ color: yellow[6] }} />: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    else{
      api.info({
        message: message,
        description: description,
        placement: 'top',  
        duration:0,
      });
    }
  };

  return (
    <NotificationContext.Provider value={{ openNotification }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
