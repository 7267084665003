import { Button, Form, Input, Typography } from "antd";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { UnitSelect } from "./UnitSelect";
import useApiClient from "../Settings/useApiClient";
import { creteriaApi } from "../API/creteriaApi";
import useNotify from "../hooks/useNotify";
import { ParsError } from "../Tools/ParsError";

const DesignCriteriaProject = forwardRef(({ onFinish,project_id }, ref) => {
  let _data = [];
  const [form] = Form.useForm();
  const [, forceUpdate]= useState(0);
  const dRef = useRef();
  const clientApi = useApiClient();
  const [Vert_HHLA_HHLS_height, setVert_HHLA_HHLS_height] = useState(150);
  const [Vert_HHLA_HHLS_unit_height, setVert_HHLA_HHLS_unit_height] =
    useState();
  const [Vert_HHLA_HHLS_time, setVert_HHLA_HHLS_time] = useState(4);
  const [Vert_HHLA_HHLS_unit_time, setVert_HHLA_HHLS_unit_time] = useState();

  const [Vert_HLA_height, setVert_HLA_height] = useState(200);
  const [Vert_HLA_unit_height, setVert_HLA_unit_height] = useState();
  const [Vert_HLA_time, setVert_HLA_time] = useState(0);
  const [Vert_HLA_unit_time, setVert_HLA_unit_time] = useState();

  const [Vert_LLA_height, setVert_LLA_height] = useState(200);
  const [Vert_LLA_unit_height, setVert_LLA_unit_height] = useState();
  const [Vert_LLA_time, setVert_LLA_time] = useState(3);
  const [Vert_LLA_unit_time, setVert_LLA_unit_time] = useState();

  const [Vert_LLLA_LLLS_height, setVert_LLLA_LLLS_height] = useState(300);
  const [Vert_LLLA_LLLS_unit_height, setVert_LLLA_LLLS_unit_height] =
    useState();
  const [Vert_LLLA_LLLS_time, setVert_LLLA_LLLS_time] = useState(0);
  const [Vert_LLLA_LLLS_unit_time, setVert_LLLA_LLLS_unit_time] = useState();

  const [Vert_HHIA_HHIS_height, setVert_HHIA_HHIS_height] = useState(150);
  const [Vert_HHIA_HHIS_unit_height, setVert_HHIA_HHIS_unit_height] =
    useState();
  const [Vert_HHIA_HHIS_time, setVert_HHIA_HHIS_time] = useState(4);
  const [Vert_HHIA_HHIS_unit_time, setVert_HHIA_HHIS_unit_time] = useState();

  const [Vert_HIA_height, setVert_HIA_height] = useState(200);
  const [Vert_HIA_unit_height, setVert_HIA_unit_height] = useState();
  const [Vert_HIA_time, setVert_HIA_time] = useState(0);
  const [Vert_HIA_unit_time, setVert_HIA_unit_time] = useState();

  const [Vert_LIA_height, setVert_LIA_height] = useState(200);
  const [Vert_LIA_unit_height, setVert_LIA_unit_height] = useState();
  const [Vert_LIA_time, setVert_LIA_time] = useState(3);
  const [Vert_LIA_unit_time, setVert_LIA_unit_time] = useState();

  const [Vert_LLIA_LLIS_height, setVert_LLIA_LLIS_height] = useState(300);
  const [Vert_LLIA_LLIS_unit_height, setVert_LLIA_LLIS_unit_height] =
    useState();
  const [Vert_LLIA_LLIS_time, setVert_LLIA_LLIS_time] = useState(0);
  const [Vert_LLIA_LLIS_unit_time, setVert_LLIA_LLIS_unit_time] = useState();

  const [Hor_HHLA_HHLS_height, setHor_HHLA_HHLS_height] = useState(100);
  const [Hor_HHLA_HHLS_unit_height, setHor_HHLA_HHLS_unit_height] = useState();
  const [Hor_HHLA_HHLS_time, setHor_HHLA_HHLS_time] = useState(4);
  const [Hor_HHLA_HHLS_unit_time, setHor_HHLA_HHLS_unit_time] = useState();

  const [Hor_HLA_height, setHor_HLA_height] = useState(200);
  const [Hor_HLA_unit_height, setHor_HLA_unit_height] = useState();
  const [Hor_HLA_time, setHor_HLA_time] = useState(0);
  const [Hor_HLA_unit_time, setHor_HLA_unit_time] = useState();

  const [Hor_LLA_height, setHor_LLA_height] = useState(100);
  const [Hor_LLA_unit_height, setHor_LLA_unit_height] = useState();
  const [Hor_LLA_time, setHor_LLA_time] = useState(3);
  const [Hor_LLA_unit_time, setHor_LLA_unit_time] = useState();

  const [Hor_LLLA_LLLS_height, setHor_LLLA_LLLS_height] = useState(300);
  const [Hor_LLLA_LLLS_unit_height, setHor_LLLA_LLLS_unit_height] = useState();
  const [Hor_LLLA_LLLS_time, setHor_LLLA_LLLS_time] = useState(0);
  const [Hor_LLLA_LLLS_unit_time, setHor_LLLA_LLLS_unit_time] = useState();

  const [Hor_HHIA_HHIS_height, setHor_HHIA_HHIS_height] = useState(100);
  const [Hor_HHIA_HHIS_unit_height, setHor_HHIA_HHIS_unit_height] = useState();
  const [Hor_HHIA_HHIS_time, setHor_HHIA_HHIS_time] = useState(4);
  const [Hor_HHIA_HHIS_unit_time, setHor_HHIA_HHIS_unit_time] = useState();

  const [Hor_HIA_height, setHor_HIA_height] = useState(200);
  const [Hor_HIA_unit_height, setHor_HIA_unit_height] = useState();
  const [Hor_HIA_time, setHor_HIA_time] = useState(0);
  const [Hor_HIA_unit_time, setHor_HIA_unit_time] = useState();

  const [Hor_LIA_height, setHor_LIA_height] = useState(100);
  const [Hor_LIA_unit_height, setHor_LIA_unit_height] = useState();
  const [Hor_LIA_time, setHor_LIA_time] = useState(3);
  const [Hor_LIA_unit_time, setHor_LIA_unit_time] = useState();

  const [Hor_LLIA_LLIS_height, setHor_LLIA_LLIS_height] = useState(150);
  const [Hor_LLIA_LLIS_unit_height, setHor_LLIA_LLIS_unit_height] = useState();
  const [Hor_LLIA_LLIS_time, setHor_LLIA_LLIS_time] = useState(0);
  const [Hor_LLIA_LLIS_unit_time, setHor_LLIA_LLIS_unit_time] = useState();
  const { openNotification } = useNotify();
  const [res, setRes] = useState(null)
  const submit = () => {
    _data = {
      vert_separator_HHLA_HHLS_Height: Vert_HHLA_HHLS_height,
      vert_separator_HHLA_HHLS_Height_unit: Vert_HHLA_HHLS_unit_height,
      vert_separator_HHLA_HHLS_Time: Vert_HHLA_HHLS_time,
      vert_separator_HHLA_HHLS_Time_unit: Vert_HHLA_HHLS_unit_time,
      hor_separator_HHLA_HHLS_Height: Hor_HHLA_HHLS_height,
      hor_separator_HHLA_HHLS_unit: Hor_HHLA_HHLS_unit_height,
      hor_separator_HHLA_HHLS_Time: Hor_HHLA_HHLS_time,
      hor_separator_HHLA_HHLS_Time_unit: Hor_HHLA_HHLS_unit_time,
      vert_separator_HLA_Height: Vert_HLA_height,
      vert_separator_HLA_Height_unit: Vert_HLA_unit_height,
      vert_separator_HLA_Time: Vert_HLA_time,
      vert_separator_HLA_Time_unit: Vert_HLA_unit_time,
      hor_separator_HLA_Height: Hor_HLA_height,
      hor_separator_HLA_Height_unit: Hor_HLA_unit_height,
      hor_separator_HLA_Time: Hor_HLA_time,
      hor_separator_HLA_Time_unit: Hor_HLA_unit_time,
      vert_separator_LLA_Height: Vert_LLA_height,
      vert_separator_LLA_Height_unit: Vert_LLA_unit_height,
      vert_separatorLLA_Time: Vert_LLA_time,
      vert_separatorLLA_Time_unit: Vert_LLA_unit_time,
      hor_separator_LLA_Height: Hor_LLA_height,
      hor_separator_LLA_Height_unit: Hor_LLA_unit_height,
      hor_separator_LLA_Time: Hor_LLA_time,
      hor_separator_LLA_Time_unit: Hor_LLA_unit_time,
      vert_separator_LLLA_LLLS_Height: Vert_LLLA_LLLS_height,
      vert_separator_LLLA_LLLS_Height_unit: Vert_LLLA_LLLS_unit_height,
      vert_separator_LLLA_LLLS_Time: Vert_LLLA_LLLS_time,
      vert_separator_LLLA_LLLS_Time_unit: Vert_LLLA_LLLS_unit_time,
      hor_separator_LLLA_LLLS_Height: Hor_LLLA_LLLS_height,
      hor_separator_LLLA_LLLS_Height_unit: Hor_LLLA_LLLS_unit_height,
      hor_separator_LLLA_LLLS_Time: Hor_LLLA_LLLS_time,
      hor_separator_LLLA_LLLS_Time_unit: Hor_LLLA_LLLS_unit_time,
      vert_separator_HHIA_HHIS_Height: Vert_HHIA_HHIS_height,
      vert_separator_HHIA_HHIS_Height_unit: Vert_HHIA_HHIS_unit_height,
      vert_separator_HHIA_HHIS_Time: Vert_HHIA_HHIS_time,
      vert_separator_HHIA_HHIS_Time_unit: Vert_HHIA_HHIS_unit_time,
      hor_separator_HHIA_HHIS_Height: Hor_HHIA_HHIS_height,
      hor_separator_HHIA_HHIS_Height_unit: Hor_HHIA_HHIS_unit_height,
      hor_separator_HHIA_HHIS_Time: Hor_HHIA_HHIS_time,
      hor_separator_HHIA_HHIS_Time_unit: Hor_HHIA_HHIS_unit_time,
      vert_separator_HIA_Height: Vert_HIA_height,
      vert_separator_HIA_Height_unit: Vert_HIA_unit_height,
      vert_separator_HIA_Time: Vert_HIA_time,
      vert_separator_HIA_Time_unit: Vert_HIA_unit_time,
      hor_separator_HIA_Height: Hor_HIA_height,
      hor_separator_HIA_Height_unit: Hor_HIA_unit_height,
      hor_separator_HIA_Time: Hor_HIA_time,
      hor_separator_HIA_Time_unit: Hor_HIA_unit_time,
      vert_separator_LIA_Height: Vert_LIA_height,
      vert_separator_LIA_Height_unit: Vert_LIA_unit_height,
      vert_separator_LIA_Time: Vert_LIA_time,
      vert_separator_LIA_Time_unit: Vert_LIA_unit_time,
      hor_separator_LIA_Height: Hor_LIA_height,
      hor_separator_LIA_Height_unit: Hor_LIA_unit_height,
      hor_separator_LIA_Time: Hor_LIA_time,
      hor_separator_LIA_Time_unit: Hor_LIA_unit_time,
      vert_separator_LLIA_LLIS_Height: Vert_LLIA_LLIS_height,
      vert_separator_LLIA_LLIS_Height_unit: Vert_LLIA_LLIS_unit_height,
      vert_separator_LLIA_LLIS_Time: Vert_LLIA_LLIS_time,
      vert_separator_LLIA_LLIS_Time_unit: Vert_LLIA_LLIS_unit_time,
      hor_separator_LLIA_LLIS_Height: Hor_LLIA_LLIS_height,
      hor_separator_LLIA_LLIS_Height_unit: Hor_LLIA_LLIS_unit_height,
      hor_separator_LLIA_LLIS_Time: Hor_LLIA_LLIS_time,
      hor_separator_LLIA_LLIS_Time_unit: Hor_LLIA_LLIS_unit_time,
    };
    creteriaApi
      .submit(clientApi, project_id, null, _data)
      .then((res) => {
        openNotification("Success", "Project saved successfully.", 200);
        onFinish();
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.status === 422) {
          openNotification("Error", ParsError(e?.response), "error");
        }
      })
      .finally(() => {});
  };
  const initialize = (res) => {
    setVert_HHLA_HHLS_height(res.vert_separator_HHLA_HHLS_Height);
    setVert_HHLA_HHLS_unit_height(res.vert_separator_HHLA_HHLS_Height_unit);
    setVert_HHLA_HHLS_time(res.vert_separator_HHLA_HHLS_Time);
    setVert_HHLA_HHLS_unit_time(res.vert_separator_HHLA_HHLS_Time_unit);

    setVert_HLA_height(res.vert_separator_HLA_Height);
    setVert_HLA_unit_height(res.vert_separator_HLA_Height_unit);
    setVert_HLA_time(res.vert_separator_HLA_Time);
    setVert_HLA_unit_time(res.vert_separator_HLA_Time_unit);

    setVert_LLA_height(res.vert_separator_LLA_Height);
    setVert_LLA_unit_height(res.vert_separator_LLA_Height_unit);
    setVert_LLA_time(res.vert_separatorLLA_Time);
    setVert_LLA_unit_time(res.vert_separatorLLA_Time_unit);

    setVert_LLLA_LLLS_height(res.vert_separator_LLLA_LLLS_Height);
    setVert_LLLA_LLLS_unit_height(res.vert_separator_LLLA_LLLS_Height_unit);
    setVert_LLLA_LLLS_time(res.vert_separator_LLLA_LLLS_Time);
    setVert_LLLA_LLLS_unit_time(res.vert_separator_LLLA_LLLS_Time_unit);

    setVert_HHIA_HHIS_height(res.vert_separator_HHIA_HHIS_Height);
    setVert_HHIA_HHIS_unit_height(res.vert_separator_HHIA_HHIS_Height_unit);
    setVert_HHIA_HHIS_time(res.vert_separator_HHIA_HHIS_Time);
    setVert_HHIA_HHIS_unit_time(res.vert_separator_HHIA_HHIS_Time_unit);

    setVert_HIA_height(res.vert_separator_HIA_Height);
    setVert_HIA_unit_height(res.vert_separator_HIA_Height_unit);
    setVert_HIA_time(res.vert_separator_HIA_Time);
    setVert_HIA_unit_time(res.vert_separator_HIA_Time_unit);

    setVert_LIA_height(res.vert_separator_LIA_Height);
    setVert_LIA_unit_height(res.vert_separator_LIA_Height_unit);
    setVert_LIA_time(res.vert_separator_LIA_Time);
    setVert_LIA_unit_time(res.vert_separator_LIA_Time_unit);

    setVert_LLIA_LLIS_height(res.vert_separator_LLIA_LLIS_Height);
    setVert_LLIA_LLIS_unit_height(res.vert_separator_LLIA_LLIS_Height_unit);
    setVert_LLIA_LLIS_time(res.vert_separator_LLIA_LLIS_Time);
    setVert_LLIA_LLIS_unit_time(res.vert_separator_LLIA_LLIS_Time_unit);

    setHor_HHLA_HHLS_height(res.hor_separator_HHLA_HHLS_Height);
    setHor_HHLA_HHLS_unit_height(res.hor_separator_HHLA_HHLS_unit);
    setHor_HHLA_HHLS_time(res.hor_separator_HHLA_HHLS_Time);
    setHor_HHLA_HHLS_unit_time(res.hor_separator_HHLA_HHLS_Time_unit);

    setHor_HLA_height(res.hor_separator_HLA_Height);
    setHor_HLA_unit_height(res.hor_separator_HLA_Height_unit);
    setHor_HLA_time(res.hor_separator_HLA_Time);
    setHor_HLA_unit_time(res.hor_separator_HLA_Time_unit);

    setHor_LLA_height(res.hor_separator_LLA_Height);
    setHor_LLA_unit_height(res.hor_separator_LLA_Height_unit);
    setHor_LLA_time(res.hor_separator_LLA_Time);
    setHor_LLA_unit_time(res.hor_separator_LLA_Time_unit);

    setHor_LLLA_LLLS_height(res.hor_separator_LLLA_LLLS_Height);
    setHor_LLLA_LLLS_unit_height(res.hor_separator_LLLA_LLLS_Height_unit);
    setHor_LLLA_LLLS_time(res.hor_separator_LLLA_LLLS_Time);
    setHor_LLLA_LLLS_unit_time(res.hor_separator_LLLA_LLLS_Time_unit);

    setHor_HHIA_HHIS_height(res.hor_separator_HHIA_HHIS_Height);
    setHor_HHIA_HHIS_unit_height(res.hor_separator_HHIA_HHIS_Height_unit);
    setHor_HHIA_HHIS_time(res.hor_separator_HHIA_HHIS_Time);
    setHor_HHIA_HHIS_unit_time(res.hor_separator_HHIA_HHIS_Time_unit);

    setHor_HIA_height(res.hor_separator_HIA_Height);
    setHor_HIA_unit_height(res.hor_separator_HIA_Height_unit);
    setHor_HIA_time(res.hor_separator_HIA_Time);
    setHor_HIA_unit_time(res.hor_separator_HIA_Time_unit);

    setHor_LIA_height(res.hor_separator_LIA_Height);
    setHor_LIA_unit_height(res.hor_separator_LIA_Height_unit);
    setHor_LIA_time(res.hor_separator_LIA_Time);
    setHor_LIA_unit_time(res.hor_separator_LIA_Time_unit);

    setHor_LLIA_LLIS_height(res.hor_separator_LLIA_LLIS_Height);
    setHor_LLIA_LLIS_unit_height(res.hor_separator_LLIA_LLIS_Height_unit);
    setHor_LLIA_LLIS_time(res.hor_separator_LLIA_LLIS_Time);
    setHor_LLIA_LLIS_unit_time(res.hor_separator_LLIA_LLIS_Time_unit);

  };
   useEffect(() => {
      if (project_id ) {
        creteriaApi.show(clientApi, project_id, null).then((res) => {
          setRes(res);
  
          initialize(res);
        });
      }
    }, [project_id]);
  useImperativeHandle(ref, () => ({
    submitForm() {
      
        form.submit();

     
    },
  }));

  return (
    <Form form={form} onFinish={submit}>
      <table ref={dRef} className="w-full text-sm text-center text-gray-500">
        <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
          <tr>
            <th
              scope="col"
              className="px-2 py-3 bg-gray-50 dark:bg-gray-800"
            ></th>
            <th
              scope="col"
              colSpan={2}
              className="px-2 py-3 bg-gray-50 dark:bg-gray-800 text-center"
            >
              Vert. Separator
            </th>

            <th
              scope="col"
              colSpan={2}
              className="px-2 py-3 bg-gray-50 dark:bg-gray-800 text-center"
            >
              Hor. Separator
            </th>
          </tr>
          <tr>
            <th
              scope="col"
              className="px-2 py-3 bg-gray-50 dark:bg-gray-800"
            ></th>
            <th scope="col" className="px-2 py-3 bg-gray-50 dark:bg-gray-800">
              Height
            </th>
            <th scope="col" className="px-2 py-3">
              Time
            </th>
            <th scope="col" className="px-2 py-3 bg-gray-50 dark:bg-gray-800">
              Height
            </th>
            <th scope="col" className="px-2 py-3">
              Time
            </th>
          </tr>
        </thead>
      <tbody>
               <tr className="border-b border-gray-200 dark:border-gray-700">
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 ><Typography.Text className="text-xs">
                   HHLA/HHLS
                   </Typography.Text>
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     onChange={(e) => setVert_HHLA_HHLS_height(e.target.value)}
                     value={Vert_HHLA_HHLS_height}
                     defaultValue={150}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HHLA_HHLS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__h_h_l_a__h_h_l_s__height_unit
                             ?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     onChange={(e) => setVert_HHLA_HHLS_time(e.target.value)}
                     value={Vert_HHLA_HHLS_time}
                     defaultValue={4}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HHLA_HHLS_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator__h_h_l_a__h_h_l_s__time_unit
                             ?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     onChange={(e) => setHor_HHLA_HHLS_height(e.target.value)}
                     value={Hor_HHLA_HHLS_height}
                     defaultValue={100}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_HHLA_HHLS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__h_h_l_a__h_h_l_s_unit?.unit_name ??
                           "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={4}
                     onChange={(e) => setHor_HHLA_HHLS_time(e.target.value)}
                     value={Hor_HHLA_HHLS_time}
                     addonAfter={
                       <UnitSelect
                         defaultUnitValue={
                           res?.hor_separator__h_h_l_a__h_h_l_s__time_unit
                             ?.unit_name ?? "min"
                         }
                         onUnitChange={setHor_HHLA_HHLS_unit_time}
                         type={"Time"}
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
               <tr className="border-b border-gray-200 dark:border-gray-700">
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 >
                   <Typography.Text className="text-xs">
                   HLA
                   </Typography.Text>
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={200}
                     onChange={(e) => setVert_HLA_height(e.target.value)}
                     value={Vert_HLA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HLA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__h_l_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     defaultValue={0}
                     onChange={(e) => setVert_HLA_time(e.target.value)}
                     value={Vert_HLA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HLA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator__h_l_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={200}
                     onChange={(e) => setHor_HLA_height(e.target.value)}
                     value={Hor_HLA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_HLA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__h_l_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={0}
                     onChange={(e) => setHor_HLA_time(e.target.value)}
                     value={Hor_HLA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_HLA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.hor_separator__h_l_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
               <tr className="border-b border-gray-200 dark:border-gray-700">
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 >
                    <Typography.Text className="text-xs">
                   LLA
                   </Typography.Text>
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={200}
                     onChange={(e) => setVert_LLA_height(e.target.value)}
                     value={Vert_LLA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LLA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__l_l_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     defaultValue={3}
                     onChange={(e) => setVert_LLA_time(e.target.value)}
                     value={Vert_LLA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LLA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator_l_l_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={100}
                     onChange={(e) => setHor_LLA_height(e.target.value)}
                     value={Hor_LLA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LLA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__l_l_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={3}
                     onChange={(e) => setHor_LLA_time(e.target.value)}
                     value={Hor_LLA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LLA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.hor_separator__l_l_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
               <tr className="border-b border-gray-200 dark:border-gray-700">
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 >
                     <Typography.Text className="text-xs">
                     LLLA/LLLS
                   </Typography.Text>
                   
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={300}
                     onChange={(e) => setVert_LLLA_LLLS_height(e.target.value)}
                     value={Vert_LLLA_LLLS_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LLLA_LLLS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__l_l_l_a__l_l_l_s__height_unit
                             ?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     defaultValue={0}
                     onChange={(e) => setVert_LLLA_LLLS_time(e.target.value)}
                     value={Vert_LLLA_LLLS_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LLLA_LLLS_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator__l_l_l_a__l_l_l_s__time_unit
                             ?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={300}
                     onChange={(e) => setHor_LLLA_LLLS_height(e.target.value)}
                     value={Hor_LLLA_LLLS_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LLLA_LLLS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__l_l_l_a__l_l_l_s__height_unit
                             ?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={0}
                     onChange={(e) => setHor_LLLA_LLLS_time(e.target.value)}
                     value={Hor_LLLA_LLLS_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LLLA_LLLS_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.hor_separator__l_l_l_a__l_l_l_s__time_unit
                             ?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
               <tr>
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 >
                      <Typography.Text className="text-xs">
                      HHIA/HHIS
                   </Typography.Text>
                  
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={150}
                     onChange={(e) => setVert_HHIA_HHIS_height(e.target.value)}
                     value={Vert_HHIA_HHIS_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HHIA_HHIS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__h_h_i_a__h_h_i_s__height_unit
                             ?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     defaultValue={4}
                     onChange={(e) => setVert_HHIA_HHIS_time(e.target.value)}
                     value={Vert_HHIA_HHIS_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HHIA_HHIS_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator__h_h_i_a__h_h_i_s__time_unit
                             ?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={100}
                     onChange={(e) => setHor_HHIA_HHIS_height(e.target.value)}
                     value={Hor_HHIA_HHIS_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_HHIA_HHIS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__h_h_i_a__h_h_i_s__height_unit
                             ?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={4}
                     onChange={(e) => setHor_HHIA_HHIS_time(e.target.value)}
                     value={Hor_HHIA_HHIS_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_HHIA_HHIS_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.hor_separator__h_h_i_a__h_h_i_s__time_unit
                             ?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
               <tr>
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 >
                     <Typography.Text className="text-xs"> 
                     HIA
                   </Typography.Text>
                  
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={200}
                     onChange={(e) => setVert_HIA_height(e.target.value)}
                     value={Vert_HIA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HIA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__h_i_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     defaultValue={0}
                     onChange={(e) => setVert_HIA_time(e.target.value)}
                     value={Vert_HIA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_HIA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator__h_i_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={200}
                     onChange={(e) => setHor_HIA_height(e.target.value)}
                     value={Hor_HIA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_HIA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__h_i_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={0}
                     onChange={(e) => setHor_HIA_time(e.target.value)}
                     value={Hor_HIA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_HIA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.hor_separator__h_i_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
               <tr>
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 >
                      <Typography.Text className="text-xs">
                      LIA
                   </Typography.Text>
                  
                  
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={200}
                     onChange={(e) => setVert_LIA_height(e.target.value)}
                     value={Vert_LIA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LIA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__l_i_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     defaultValue={3}
                     onChange={(e) => setVert_LIA_time(e.target.value)}
                     value={Vert_LIA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LIA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator__l_i_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={100}
                     onChange={(e) => setHor_LIA_height(e.target.value)}
                     value={Hor_LIA_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LIA_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__l_i_a__height_unit?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={3}
                     onChange={(e) => setHor_LIA_time(e.target.value)}
                     value={Hor_LIA_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LIA_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.hor_separator__l_i_a__time_unit?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
               <tr>
                 <th
                   scope="row"
                   className="px-0 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                 >
                     <Typography.Text className="text-xs">
                     LLIA/LLIS
                   </Typography.Text>
                  
                 </th>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={300}
                     onChange={(e) => setVert_LLIA_LLIS_height(e.target.value)}
                     value={Vert_LLIA_LLIS_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LLIA_LLIS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.vert_separator__l_l_i_a__l_l_i_s__height_unit
                             ?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50 dark:bg-gray-800">
                   <Input
                     defaultValue={0}
                     onChange={(e) => setVert_LLIA_LLIS_time(e.target.value)}
                     value={Vert_LLIA_LLIS_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setVert_LLIA_LLIS_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.vert_separator__l_l_i_a__l_l_i_s__time_unit
                             ?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4">
                   <Input
                     defaultValue={150}
                     onChange={(e) => setHor_LLIA_LLIS_height(e.target.value)}
                     value={Hor_LLIA_LLIS_height}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LLIA_LLIS_unit_height}
                         type={"Length"}
                         defaultUnitValue={
                           res?.hor_separator__l_l_i_a__l_l_i_s__height_unit
                             ?.unit_name ?? "mm"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
                 <td className="px-0 py-4 bg-gray-50">
                   <Input
                     defaultValue={0}
                     required
                     onChange={(e) => setHor_LLIA_LLIS_time(e.target.value)}
                     value={Hor_LLIA_LLIS_time}
                     addonAfter={
                       <UnitSelect
                         onUnitChange={setHor_LLIA_LLIS_unit_time}
                         type={"Time"}
                         defaultUnitValue={
                           res?.hor_separator__l_l_i_a__l_l_i_s__time_unit
                             ?.unit_name ?? "min"
                         }
                       />
                     }
                     type="number"
                     style={{ width: 130 }}
                     className="input-addon-general focus:border-none focus:outline-none"
                   />
                 </td>
               </tr>
        
             </tbody>
      </table>
    </Form>
  );
});

export default DesignCriteriaProject;
