import React, { useEffect, useState } from "react";
import { makeTable2 } from "../helpers/makeData";
import { Table } from "antd";
import { weightApi } from "../API/weightApi";
import useApiClient from "../Settings/useApiClient";
import { findMinimumCases } from "../Tools/lib";

const CaseTable = ({ sizing_id,case_study }) => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [minimumCase, setMinimumCase] = useState()

  const apiClient = useApiClient();
  const loadData = () => {
    if(sizing_id)
    weightApi.load(apiClient, sizing_id).then((r) => {
      load(r.weights.datasource);
    });
  };
  const load = (datasource) => {
    makeTable2(datasource).then((res) => {
      const _cases=findMinimumCases(res.dataSource)
      setMinimumCase(_cases[0].id)
      setColumns(res.columns);
      setDataSource(res.dataSource);
    });
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("case_study", loadData);
    }
    loadData();
    return () => {
      window.removeEventListener("case_study", loadData);
    };
  }, []);

  return (
    <>
      <Table
        size={"small"}
        pagination={false}
        className="mb-2 w-auto m-auto text-center"
        // scroll={{
          // y: 55 * 5,
          //  x:10*5
        // }}
        dataSource={dataSource}
        columns={columns}
      />
      <div className="w-full items-center justify-center  m-auto flex gap-10 mb-5">
        <strong>Selected Case No:{case_study??"N/A"}</strong>
        <strong>Minimum Case No:{minimumCase}</strong>
      </div>
    </>
  );
};

export default CaseTable;
