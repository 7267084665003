import React from 'react'

const SeparatorConfigurations = ({res,I40}) => {
  return (
    <table
    style={{
      borderCollapse: "collapse",
      width: "90%",
      textAlign: "center",
      margin: "auto",
    }}
  >
    <thead>
      <tr>
        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          LLIA/LLIS
        </th>
        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          LIA
        </th>

        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          HHIA_HHIS
        </th>

        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          LLLA_LLLS
        </th>
        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          LLA
        </th>
        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          HHLA_HHLS
        </th>
        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          Demister
        </th>
        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          Diverter
        </th>
        <th style={{ border: "1px solid black", padding: "3px 8px" }}>
          Coalescer
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 7 || I40 == 8
            ? ""
            : res.configuration?.LLIA_LLIS == 1
            ? "✔"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 7 || I40 == 8
            ? ""
            : res.configuration?.LIA == 1
            ? "✔"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 7 || I40 == 8
            ? ""
            : res.configuration?.HHIA_HHIS == 1
            ? "✔"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.LLLA_LLLS == 1 ? "✔" : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.LLA == 1 ? "✔" : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.HHLA_HHLS == 1 ? "✔" : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.Demister == 1 ? "✔" : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.Diverter == 1 ? "✔" : ""}
        </td>

        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 1 || I40 == 2 || I40 == 7
            ? ""
            : res.configuration?.Coalescer == 1
            ? "✔"
            : ""}
        </td>
      </tr>
      <tr>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 8 || I40 == 7
            ? ""
            : res.configuration?.LLIA_LLIS == 1
            ? res.data?.D50 + " mm"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 8 || I40 == 7
            ? ""
            : res.configuration?.LIA == 1
            ? res.data?.G50 + " mm"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 8 || I40 == 7
            ? ""
            : res.configuration?.HHIA_HHIS == 1
            ? res.data?.N50 + " mm"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.LLLA_LLLS == 1
            ? res.data?.Q50 + " mm"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.LLA == 1 ? res.data?.T50 + " mm" : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.HHLA_HHLS == 1
            ? res.data?.AA50 + " mm"
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          -
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          -
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          -
        </td>
      </tr>
      <tr>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 8 || I40 == 7
            ? ""
            : res.configuration?.LLIA_LLIS == 1
            ? Math.round((res.data?.D50 / 25.4) * 100) / 100 + " in."
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 8 || I40 == 7
            ? ""
            : res.configuration?.LIA == 1
            ? Math.round((res.data?.G50 / 25.4) * 100) / 100 + " in."
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {I40 == 8 || I40 == 7
            ? ""
            : res.configuration?.HHIA_HHIS == 1
            ? Math.round((res.data?.N50 / 25.4) * 100) / 100 + " in."
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.LLLA_LLLS == 1
            ? Math.round((res.data?.Q50 / 25.4) * 100) / 100 + " in."
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.LLA == 1
            ? Math.round((res.data?.T50 / 25.4) * 100) / 100 + " in."
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          {res.configuration?.HHLA_HHLS == 1
            ? Math.round((res.data?.AA50 / 25.4) * 100) / 100 + " in."
            : ""}
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          -
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          -
        </td>
        <td style={{ border: "1px solid black", padding: "3px 8px" }}>
          -
        </td>
      </tr>
    </tbody>
  </table>
  )
}

export default SeparatorConfigurations