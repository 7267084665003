import React from 'react'
import { toFixed } from '../../helpers/Converts'
import { I106f, I111f, O106f, O111f } from '../../helpers/reportHelp'

const Volumes = ({res,I40}) => {
  return (
    <table style={{ borderCollapse: 'collapse', width: '50%' }}>
    <thead>
      <tr>
        <th
          colSpan={5}
          className="text-start !text-[11px] !font-bold"
        >
          Volumes
        </th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th
          style={{ border: '1px solid black', padding: '3px 8px' }}
          colSpan={2}
        >
          Si
        </th>
        <th
          style={{ border: '1px solid black', padding: '3px 8px' }}
          colSpan={2}
        >
          Field
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          style={{
            border: '1px solid black',
            padding: '0px',
            textAlign: 'center',
            writingMode: 'vertical-lr',
            transform: 'rotate(180deg)',
          }}
          rowSpan={5}
        >
          Light Liquid
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          HHL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed( res.data?.S79,2)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         m<sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed( res.data?.AM79,2)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
       m<sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         HL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed( res.data?.N79,2)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          m<sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed( res.data?.AI79,3)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          m<sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          NL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed(I106f(I40,res.data.BD75, res.data.AH71, res.data.BH79, res.data.AV79),3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          m<sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         {toFixed(O106f(I40,I106f(I40,res.data.BD75, res.data.AH71, res.data.BH79, res.data.AV79), res.data.BD75, res.data.AH71, res.data?.AR83),3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         m<sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          LL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
           {toFixed( res.data?.I79,3)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          m<sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed( res.data?.AE79,3)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m <sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         LLL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         {toFixed( res.data?.D79,3)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
      m <sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
           {toFixed( res.data?.AA79,3)}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m<sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{
            border: '1px solid black',
            padding: '0px',
            textAlign: 'center',
            writingMode: 'vertical-lr',
            transform: 'rotate(180deg)',
          }}
          rowSpan={5}
        >
          Heavy Liquid
        </td>

        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          HHL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
            {toFixed( res.data?.S75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m <sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed( res.data?.AM75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
      m   <sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          HL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
             {toFixed( res.data?.N75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m  <sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
            {toFixed( res.data?.AI75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m <sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         NL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         {toFixed( I111f(I40, res.data?.N71,res.data?. BD75, res.data?.BH79, res.data?.AR79, res.data?.BD79, res.data?.AR75),3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m <sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {   toFixed( O111f(I40,I111f(I40, res.data?.N71,res.data?. BD75, res.data?.BH79, res.data?.AR79, res.data?.BD79, res.data?.AR75),res.data?.AR75, res.data?.BD75, res.data?.N71, res.data?.AR83),3)||"-"}

        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m<sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          LL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
           {toFixed( res.data?.I75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
      m   <sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        {toFixed( res.data?.AE75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m  <sub className='align-top'>3</sub>
        </td>
      </tr>
      <tr>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         LLL
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
         {toFixed( res.data?.D75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
       m <sub className='align-top'>3</sub>
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
          {toFixed( res.data?.AA75,3)||"-"}
        </td>
        <td
          style={{ border: '1px solid black', padding: '3px 8px' }}
        >
        m <sub className='align-top'>3</sub>
        </td>
      </tr>
    </tbody>
  </table>
  )
}

export default Volumes