import { Button, Card } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GeneralConfigurations } from './GeneralConfigurations';
import TypeSpecificConfiguration from './TypeSpecificConfiguration';
import { ProjectContext } from '../Context/ProjectContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const Configurations = () => {
  const { setSizingLoading, setActive } = useContext(ProjectContext);
  const { project_id, id } = useParams();
  const gcRef = useRef();
  const navigate = useNavigate();
  const [I40, setI40] = useState(1);
  const tscRef = useRef();
  const [tscFinish, setTscFinish] = useState(false);
  const [gcFinish, setGcFinish] = useState(false);
  const [status, setStatus] = useState(false);
  const submitGCForm = (status) => {
    setGcFinish(false);
    setTscFinish(false);
    setStatus(status);
    gcRef.current.submitForm(status);
    tscRef.current.submitForm(status);
  };

  useEffect(() => {
    if (gcFinish && tscFinish && status) {
      setTimeout(() => {
        setSizingLoading(1);
      }, 1000);
    } else if (gcFinish && tscFinish && !status) navigate('/');
  }, [gcFinish, tscFinish]);
  return (
    <Card style={{}} type="inner" title="Design">
      <GeneralConfigurations
        ref={gcRef}
        setGcFinish={setGcFinish}
        setI40={setI40}
      />
      <TypeSpecificConfiguration
        ref={tscRef}
        setTscFinish={setTscFinish}
        I40={I40}
      />
      <div className="w-full flex text-center justify-between items-center mt-5 pt-5">
      <Button
      onClick={()=>{setActive("process")}}
         icon={<ArrowLeftOutlined/>}
          className=" w-20   mt-5 text-black hover:bg-gray-900  font-medium  text-sm px-8 py-5 me-2 "
        >
          Back
        </Button>
        <Button
          onClick={() => submitGCForm(true)}
          className="  w-36 mt-5 text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8 py-5 me-2 "
        >
          Save and Size
        </Button>
        <Button
          onClick={() => submitGCForm(false)}
          className=" w-36   mt-5 text-black hover:bg-gray-900  font-medium  text-sm px-8 py-5 me-2 "
        >
          Save and Close
        </Button>
        {/* <Link
          target="_blank"
          to={`/result/${project_id}/${id}`}
          className="   mt-5 text-black border rounded-sm  font-medium  text-sm px-8 py-2 me-2 "
        >
          Details
        </Link> */}
        <Button
      onClick={()=>{setActive("process")}}
         icon={<ArrowRightOutlined/>}
         disabled
         iconPosition='end'
          className=" w-20   mt-5 text-black hover:bg-gray-900  font-medium  text-sm px-8 py-5 me-2 "
        >
          Next
        </Button>
      </div>
      <div className="w-full tex-center m-auto  mt-4 flex">
        <a
          className="text-center m-auto  flex items-center"
          onClick={() => setActive('Design_specifications')}
          href="#"
        >
          Weight analysis <img src="/images/arrow-down.svg" />
        </a>
      </div>
    </Card>
  );
};

export default Configurations;
