import Global from "../Settings/config.json";
export const ProjectApi= {
    
    async projects(apiClient) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}projects`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async removeLogo(apiClient,id,fileId) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/project/${id}/logo`,{"file":fileId})
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async new(apiClient,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/project`,data,{headers:{
                "content-type":'multipart/form-data'
            }})
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async update(apiClient,pid,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/project/update/${pid}`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async delete(apiClient,id) {
        let res = [];
        await apiClient
            .delete(`${Global.SERVER}user/project/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 201:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async archive(apiClient,id) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/project/archive/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 204:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async load(apiClient,id,CRETERIA=false) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/project/${id}${CRETERIA?"?creteria=1":''}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },

}