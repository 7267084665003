import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case8_2({info}) {
    return (
        <>
              
                 <div className="HHLL-H grid text-start">
                             <div className='flex items-center justify-between gap-1  leading-4'><span>HHLL</span><span>{toFixed(info.AN71,0)}</span></div>
                                                           <div className='flex items-center justify-between gap-1'><span>HLL</span><span>{toFixed(info.AK71,0)}</span></div>
                                                           <div className='flex items-center justify-between gap-1'><span>NLL</span><span>{toFixed(info.AH71,0)}</span></div>
                                                           <div className='flex items-center justify-between gap-1'><span>LLL</span><span>{toFixed(info.AA71,0)}</span></div>
                                                           <div className='flex items-center justify-between gap-1'><span>LLLL</span><span>{toFixed(info.X71,0)}</span></div>
                             </div>
                 
                          
                        <div className="L-H-8">
                                   <label>L </label>{toFixed(info.BH75,3)}
                                 </div>
                                 <div className="D-H-8">
                                   <label>D </label>{toFixed(info.BD75,3)}
                                 </div>
                             <div className="hv-H-8 text-center">
                               <label>
                                 H<sub className="align-sub ">v</sub>
                               </label>
                               <br />
                               {toFixed(info.AR71, 0)}
                             </div>
        </>)
}

export default Case8_2